import React from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Textfield from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Arrow from '@elsdoerfer/react-arrow'

import withWidth from '@material-ui/core/withWidth'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Header from '../components/header'
import ChatWidget from '../components/chat-widget'
import PhoneCall from '../components/phone-call'
import Footer from '../components/footer'

import highLevelFlight from '../images/high-level-flight.png'
import logo from '../images/retair_slogan.png'
import shop from '../images/shop.jpg'
import accountingSystem from '../images/accounting-system.jpg'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

import MessageDialog from '../components/message-dialog'
import LeftOrderDialog from '../components/left-order'
import {getChatToken, setChatToken} from '../components/chat-widget/token.js'

import ym from 'react-yandex-metrika'


const data = [
  {
    pin:'SA520305C',
    brand:'Sabo',
    mt:'автомат',
    pc:'91,6 ₽',
    st_ma:12,
    st:12,
    lid:8,
    s30:'',
    s0:'',
    m30:'',
    m0:''
  },
  {pin:'18200249',brand:'Frenotruck',mt:'автомат',pc:'89,8 ₽',st_ma:12,st:12,lid:9,s30:'',s0:'',m30:'',m0:''},
  {pin:'SA520483',brand:'Sabo',mt:'автомат',pc:'50,9 ₽',st_ma:23,st:20,lid:10,s30:'4',s0:'3',m30:'5%',m0:'5%'},
  {pin:'K003996N00',brand:'Knorr',mt:'автомат',pc:'55,5 ₽',st_ma:17,st:17,lid:11,s30:'',s0:'1',m30:'',m0:'1%'},
  {pin:'201.145',brand:'Sampa',mt:'ручной',pc:'50.1 ₽',st_ma:20,st:20,lid:10,s30:'',s0:'',m30:'',m0:''},
  {pin:'20247',brand:'Febi',mt:'автомат',pc:'58.7 ₽',st_ma:16,st:13,lid:19,s30:'',s0:'3',m30:'',m0:'2%'},
  {pin:'F5021',brand:'Monroe',mt:'автомат',pc:'52,9 ₽',st_ma:17,st:17,lid:15,s30:'',s0:'',m30:'',m0:''},
  {pin:'CKSK.13.1',brand:'Trucktechnic',mt:'автомат',pc:'14,5 ₽',st_ma:62,st:62,lid:23,s30:'',s0:'',m30:'',m0:''},
  {pin:'1.21554',brand:'Diesel Technic',mt:'автомат',pc:'36,5 ₽',st_ma:24,st:24,lid:16,s30:'1',s0:'',m30:'-2%',m0:''},
  {pin:'18400200',brand:'Frenotruck',mt:'ручной',pc:'96,6 ₽',st_ma:9,st:9,lid:14,s30:'',s0:'',m30:'',m0:''},
  {pin:'35598',brand:'Febi',mt:'автомат',pc:'34,7 ₽',st_ma:25,st:25,lid:29,s30:'2',s0:'3',m30:'-7%',m0:'-7%'},
  {pin:'581503',brand:'CEI',mt:'автомат',pc:'85,1 ₽',st_ma:10,st:10,lid:27,s30:'',s0:'',m30:'',m0:''},
  {pin:'4.61902',brand:'Diesel Technic',mt:'автомат',pc:'76,7 ₽',st_ma:10,st:11,lid:19,s30:'',s0:'',m30:'',m0:''},
]


function Widget(props) {


  return (
    

    <Grid item xs={3}
      style={{
        height: '10em',
        padding:'0.5em'
      }}
    >
      <div
        style={{width:'100%', height:'100%',backgroundColor: grey[900], position:'relative',
          padding:'0.7em'
        }}
      >
        <Typography align='right'
          style={{
            fontSize:(props.id<4)?'1.5em':(props.id<7)?'1.0em':'0.6em',
            width:'85%',top:'50%',position:'absolute'
          }}
        >
          {props.id}
        </Typography>
        <Typography align='left' color="textSecondary"
          style={{
            fontSize:'0.7em',
            marginLeft: '0.7em',
          }}
        >
          {props.sku+" артикулов"}
        </Typography>
        <Typography align='left' color="textSecondary" style={{fontSize:'0.7em',marginLeft: '0.7em',marginTop: '0.7em',}}>
          {"Себестоимость остатков: "+props.stock+" ₽"}
        </Typography>
        <Typography align='left' color="textSecondary" style={{fontSize:'0.7em',marginLeft: '0.7m',marginTop: '0.7em',width:'75%'}}>
          Динамика продаж за посл 4 мес
        </Typography>

        <Grid container>
          <Grid item xs={3} style={{padding:'0.15em'}}><div style={{backgroundColor:grey[800], width:'95%',height:'2em'}}/></Grid>
          <Grid item xs={3} style={{padding:'0.15em'}}><div style={{backgroundColor:grey[800], width:'95%',height:'2em'}}/></Grid>
          <Grid item xs={3} style={{padding:'0.15em'}}><div style={{backgroundColor:grey[800], width:'95%',height:'2em'}}/></Grid>
          <Grid item xs={3} style={{padding:'0.15em'}}><div style={{backgroundColor:grey[800], width:'95%',height:'2em'}}/></Grid>
        </Grid>
      </div>
      
    </Grid>

  )
}


function NonsellerPriceManagement(props) {

  document.title = 'Retair - Цены неликвидов'

  const useStyles = makeStyles((theme) => ({

    logo: {
      opacity: '0.8',
      top: theme.spacing(10),
      cursor:'pointer',
      zIndex: '1',
      position: 'absolute'
    },
    fastWinCaption: {
      color: 'white',
      marginTop: theme.spacing(2),
    },
    phylosophy: {
      color: 'white',
      marginTop: theme.spacing(2),
      fontSize:'1.2em'
    },
    keyConsideration: {
      color: 'white',
      marginTop: theme.spacing(2),
      fontSize:'1.5em'
    },
    media: {
      height: 140,
    },
    tablecell: {
      fontSize:'0.8em'
    },
    textfield: {
      fontSize:'0.9em',
      paddingTop:'0.1em'
    },
    textfield2: {
      fontSize:'0.9em',
      paddingTop:'0'
    },
    radiolabel: {
      fontSize:'1em'
    }


  }))

  const classes = useStyles()

  const [logoCoefficient, setLogoCoefficient] = React.useState(0.0)
  const match1 = useMediaQuery('(max-width:950px)')
  const match2 = useMediaQuery('(max-width:1120px)')
  const match3 = useMediaQuery('(max-width:1200px)')
  const match4 = useMediaQuery('(max-width:1300px)')

  const [openDialog, setOpenDialog] = React.useState(false)
  const [openMessageDialog,setOpenMessageDialog] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [service, setService] = React.useState('')

  React.useEffect(()=>{
    if(!match4) setLogoCoefficient(1.0)
    else if(!match3) setLogoCoefficient(0.9)
    else if(!match2) setLogoCoefficient(0.8)
    else if(!match1) setLogoCoefficient(0.6)
    else setLogoCoefficient(0)
  },[match1,match2,match3])

  const handleSend = () => {
    setOpenDialog(false)
    fetch('/send-message/', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({token: getChatToken(),message: 'Оставил заявку на рассказ бизнес-эксперта о приложении Управление ценами неликвидов. Мои контакты: электронная почта - '+email+', телефон - '+phoneNumber}),
        json: true,
    })
    setOpenMessageDialog(true)
  }

  return (
    <React.Fragment>
      <Header width={props.width} title=''/>
      <ChatWidget width={props.width}/>
      <PhoneCall/>
      <main style={{marginTop:'6em'}}>
        <div className={classes.heroContent} style={{backgroundImage: 'url(' + highLevelFlight + ')', backgroundPosition:'center'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '8.0vh', paddingTop: (!match1)?'1.5vh':'4.0vh'}}>
            <Typography component="h1" align="center" color="textPrimary" gutterBottom style={{color:'white',marginTop:'3vh',fontSize:(match1)?'1.9em':'2.5em'}}>
              Retair.ЦеныНеликвидов
            </Typography>
            <Grid container>
              <Grid item xs={(match1)?4:6}/>
              <Grid item xs={(match1)?8:6} style={{marginTop:'3vh'}}>
                <Typography component="h1" variant="h5" align="left" color="textPrimary" gutterBottom style={{color:'white'}}>
                  Высший пилотаж в ценообразовании для неликвидов
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={(match1)?4:6}/>
              <Grid item xs={(match1)?8:6}>
                <Typography variant="h6" align="left" color="textSecondary" paragraph style={{color:'white',fontSize:'1em'}}>
                  Максимизирует прибыль при распродаже
                </Typography>
              </Grid>
              <Grid item xs={6}/>
              <Grid item xs={6}>
                <div>
                  <Grid container spacing={2} justify="left">
                    <Grid item>
                      <Button variant="contained" color="primary" id="retair-npm-left-order-button"
                        onClick={e=>{ym('reachGoal', 'retair-npm-left-order-button', {}); setOpenDialog(true)}}
                      >
                        Оставить заявку
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <img src={logo} className={classes.logo} alt={"Логотип Retair"}
          style={{
            width: logoCoefficient*22 + 'em',
            height: logoCoefficient*11 + 'em',
            marginBottom: 1 + 'em',
            marginTop: 4 + 'em',
            marginLeft: logoCoefficient*8 + 'em',
          }}
        />
        <div style={{backgroundColor:grey[900], width:'100%'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '2.0vh', paddingTop: '2.0vh'}}>
            <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh'}}>
              Как работает сервис?
            </Typography>
            <Grid container>
              <Grid item xs={4} style={{height:(match1)?'10em':'5em', position: 'relative'}}>
                <div style={{}}>
                  <Typography align='left' 
                    style={{
                      fontSize:'1.2em', position: 'absolute', bottom:'0', backgroundColor:grey[800], 
                      padding:'1em',
                      marginBottom:'0.2em',
                    }}
                  >
                    Для товара устанавливается стартовая цена
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={4}/>
              <Grid item xs={4}/>

              <Grid item xs={4} style={{}}>
                <Typography align='left'
                  style={{fontSize:'0.8em', paddingLeft:'1em',}}>
                  - по последним двум сделкам с положительной маржинальной наценкой
                </Typography>
                <Typography align='left' style={{fontSize:'0.8em', paddingLeft:'1em'}}>
                  - либо устанавливается фиксированная маржинальная наценка
                </Typography>
              </Grid>
              <Grid item xs={4} style={{height:(match1)?'15em':'5em',borderLeft:'2px solid '+grey[700]}}/>
              <Grid item xs={4}/>

              <Grid item xs={4}/>
              <Grid item xs={4}>
                <Typography align='left'
                  style={{
                    fontSize:'1.2em', backgroundColor:grey[800],
                    padding:'1em',
                    marginBottom:'0.2em',marginTop:'0.2em',
                  }}
                >
                  Сервис ожидает пока клиенты 10 раз не увидят цену, и далее снижает её на 1 шаг
                </Typography>
              </Grid>
              <Grid item xs={4}/>

              <Grid item xs={4}/>
              <Grid item xs={4}>
                <Typography align='left' style={{fontSize:'0.8em', marginLeft:'2em'}}>
                  цифра 10 является конфигурируемым параметром
                </Typography>
              </Grid>
              <Grid item xs={4} style={{height:(match1)?'15em':'5em',borderLeft:'2px solid '+grey[700]}}/>

              <Grid item xs={4}/>
              <Grid item xs={4}/>
              <Grid item xs={4} style={{marginBottom:'5em'}}>
                <Typography align='left'
                  style={{
                    fontSize:'1.2em', backgroundColor:grey[800],
                    padding:'1em',marginTop:'0.2em',
                  }}
                >
                  Цена фиксируется после сделки
                </Typography>
              </Grid>

            </Grid>
          </Container>
        </div>

        <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '3.0vh', paddingTop: '3.0vh'}}>
          <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh'}}>
            Что обязательно нужно для запуска сервиса?
          </Typography>

          <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:'center',alignItems:'space-between'}}>


            <Card className={classes.root}
              style={{
                backgroundColor:grey[700],
                margin: '1.5em',
                width: '350px'
              }}
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={shop}
                />
                <CardContent>
                  <Typography component="h6" variant="h6"  align="center" style={{width:"100%"}}>
                    Поисковые запросы в ЭТП
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Именно по лидам (поисковым запросам), собранным в электронной торговой площадке (интернет-магазине), сервис убеждается, что клиент действительно увидел цену
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>


            <Card className={classes.root}
              style={{
                backgroundColor:grey[700],
                margin: '1.5em',
                width: '420px'
              }}
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={accountingSystem}
                />
                <CardContent>
                  <Typography component="h6" variant="h6"  align="center" style={{width:"100%"}}>
                    Поисковые запросы в учетной системе
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Качество результата сервиса увеличивается при сборе поисковых запросов в учетной системе, когда менеджер по продажам "пробивает" цену и наличие товара при очном посещении клиентом торговой точки
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

          </div>
        </Container>

        <div style={{backgroundColor:grey[900], width:'100%'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '2.0vh', paddingTop: '2.0vh'}}>
            <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption}>
              Категоризация неликвидов
            </Typography>

            <Grid container>

              <Grid item xs={(!match1)?3:12} style={{marginTop: '2.0vh'}}>
                <Typography component="h6" variant="h6"  align="left" style={{width:"100%",fontSize:'1.2em'}}>
                  В системе все неликвиды автоматически делятся на 12 категорий
                </Typography>
                <Typography component="h6" variant="h6"  align="left" style={{width:"100%",fontSize:'1.2em', marginTop:'2vh'}}>
                  По горизонтали:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p"  align="left" style={{paddingLeft:'1em'}}>
                  4 уровня себестоимости товара
                </Typography>
                <Typography component="h6" variant="h6"  align="left" style={{width:"100%",fontSize:'1.2em', marginTop:'2vh'}}>
                  По вертикали:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p"  align="left" style={{paddingLeft:'1em'}}>
                  3 уровня по частоте поисковых запросов
                </Typography>
              </Grid>

              <Grid item xs={(!match1)?9:12} style={{paddingLeft:'10px', overflowX:'auto', overflowY:'hidden',marginTop: '2.0vh'}}>
                <div style={{width: '47.5em', backgroundColor:grey[800],padding:'1em', height:'38em',position:'relative'}}>
                  <div style={{width: '100%', height:'50px'}}>
                    <Typography align="center" style={{width:"100%",fontSize:'1em'}}>
                      Себестоимость 
                    </Typography>
                    <Arrow
                      angle={90}
                      length={750}
                      color='#39B9E5'
                      style={{
                        width: '90%'
                      }}
                    />
                  </div>
                  <div style={{width: '38em',transform: 'rotate(-90deg)', height:'50px', position:'absolute', top:'18em',left:'-17em'}}>
                    <Typography align="center" style={{width:'100%',fontSize:'1em'}}>
                      Поисковые запросы 
                    </Typography>
                    <Arrow
                      angle={-90}
                      length={750}
                      color='#39B9E5'
                      style={{
                        width: '90%'
                      }}
                    />
                  </div>

                  <div style={{position:'absolute', left:'40px'}}>
                    <div style={{width:'3em', height:'5em'}}/>
                    <Typography align="center" style={{width:"100%",fontSize:'0.8em'}}>
                      0 - 5
                    </Typography>
                    <Typography align="center" style={{width:"100%",fontSize:'0.8em', height:'11em'}}>
                      запр/мес
                    </Typography>

                    <Typography align="center" style={{width:"100%",fontSize:'0.8em'}}>
                      5 - 30
                    </Typography>
                    <Typography align="center" style={{width:"100%",fontSize:'0.8em', height:'10em'}}>
                      запр/мес
                    </Typography>

                    <Typography align="center" style={{width:"100%",fontSize:'0.8em'}}>
                      > 30
                    </Typography>
                    <Typography align="center" style={{width:"100%",fontSize:'0.8em', height:'8em'}}>
                      запр/мес
                    </Typography>
                  </div>

                  <Grid container style={{width:'580px',position:'absolute',left:'80px',top:'50px'}}>
                    <Grid item xs={3}>
                      <Typography align="center" style={{width:"100%",fontSize:'0.8em', height:'2em'}}>
                        0 - 100₽
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography align="center" style={{width:"100%",fontSize:'0.8em', height:'2em'}}>
                        100₽ - 1000₽
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography align="center" style={{width:"100%",fontSize:'0.8em', height:'2em'}}>
                        1000₽ - 3000₽
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography align="center" style={{width:"100%",fontSize:'0.8em', height:'2em'}}>
                        > 3000₽
                      </Typography>
                    </Grid>

                    <Widget id={10} sku="210" stock="19 974"/>
                    <Widget id={3} sku="608" stock="228 973"/>
                    <Widget id={5} sku="173" stock="187 225"/>
                    <Widget id={12} sku="1" stock="3 498"/>

                    <Widget id={8} sku="172" stock="5 454"/>
                    <Widget id={2} sku="1396" stock="312 215"/>
                    <Widget id={1} sku="549" stock="490 075"/>
                    <Widget id={7} sku="10" stock="35 871"/>

                    <Widget id={11} sku="210" stock="19 974"/>
                    <Widget id={6} sku="427" stock="88 657"/>
                    <Widget id={4} sku="278" stock="217 072"/>
                    <Widget id={9} sku="12" stock="25 652"/>

                  </Grid>

                </div>
              </Grid>

            </Grid>
          </Container>
        </div>



        <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '4.0vh', paddingTop: '4.0vh'}}>
          <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '4.0vh'}}>
            Два варианта массовой установки стартовых цен
          </Typography>
          <div style={{display:'flex', flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',alignItems:'center',marginTop:'4.0vh',marginBottom:'4.0vh'}}>
            <div style={{padding:'0.5em',marginLeft:'1em',marginTop:'1em'}}>
              <Button color='primary' variant='contained' style={{fontSize:'1em', width:'20em'}}>
                Сбросить все цены до уровня посл 2 сделок с положительной маржинальной наценкой
              </Button>
            </div>
            <div style={{padding:'0.5em',marginLeft:'1em',marginTop:'1em'}}>
              <Button color='primary' variant='contained' style={{fontSize:'1em', width:'20em'}}>
                Установить фиксированную маржинальную наценку
              </Button>
            </div>
          </div>
        </Container>


        <div style={{backgroundColor:grey[900], width:'100%'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '6.0vh', paddingTop: '4.0vh'}}>
            <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh'}}>
              Параметры автоматического управления ценой
            </Typography>

            <Grid container>
              <Grid item xs={(!match1)?8:12} style={{marginTop: '4.0vh'}}>
                <div style={{backgroundColor:grey[800], padding:'1em', position:'relative'}}>
                  <div style={{position:'absolute', right:'1em'}}>
                    <Typography align="right" color="textSecondary" style={{fontSize:'0.8em'}}>
                      100₽ - 1000₽
                    </Typography>
                    <Typography align="right" color="textSecondary" style={{fontSize:'0.8em',marginTop:'1em'}}>
                      5 - 30 запр/мес
                    </Typography>
                  </div>

                  <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', marginTop:'0.5em'}}>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.2em'}}>
                      Количество лидов до очередного снижения цены:
                    </Typography>
                    <Textfield style={{width: '5em', marginLeft:'1em'}} value={20}/>
                  </div>

                  <div style={{display:'flex', flexDirection:'row', marginTop:'0.5em'}}>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.2em'}}>
                      Шаг снижения цены:
                    </Typography>
                    <Textfield style={{width: '5em', marginLeft:'1em'}} value={"-1,5%"}/>
                  </div>

                  <div style={{display:'flex', flexDirection:'row', marginTop:'0.5em'}}>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.2em'}}>
                      Минимальная наценка:
                    </Typography>
                    <Textfield style={{width: '5em', marginLeft:'1em'}} value={"-40%"}/>
                  </div>

                  <div style={{display:'flex', flexDirection:'row', marginTop:'0.5em'}}>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.2em'}}>
                      После сделки цена фиксируется на дней:
                    </Typography>
                    <Textfield style={{width: '5em', marginLeft:'1em'}} value={20}/>
                  </div>

                  
                </div>
              </Grid>

              <Grid item xs={(!match1)?4:12} style={{paddingLeft:'1em',paddingTop:'1em',marginTop: '4.0vh'}}>
                <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.2em'}}>
                  С помощью всего 4 параметров осуществляется качественный поиск ликвидной цены
                </Typography>
              </Grid>

            </Grid>

          </Container>
        </div>



        <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '6.0vh', paddingTop: '4.0vh'}}>
          <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '4.0vh'}}>
            В любой момент можно приостановить работу сервиса
          </Typography>
          <div style={{display:'flex', flexDirection:'row',flexWrap:'wrap',justifyContent:'center',marginTop:'2em'}}>
            <div style={{marginLeft:'1em',marginTop:'1em', backgroundColor:grey[900], padding:'2em'}}>
              <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.3em'}}>
                Включить автоматическое управление
              </Typography>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center',justifyContent:'center',marginTop:'1em'}}>
                <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.3em', marginRight:'1em'}}>
                  выкл
                </Typography>
                <Switch checked={true} color='primary'/>
                <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.3em',marginLeft:'1em'}}>
                  вкл
                </Typography>
              </div>
            </div>
          </div>
        </Container>





        <div style={{backgroundColor:grey[900], width:'100%'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '2.0vh', paddingTop: '2.0vh'}}>
            <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh'}}>
              Список товаров в категории
            </Typography>

            <Grid container>
              <Grid item xs={(!match1)?3:12} style={{padding:'2em'}}>
                <Typography component="h6" variant="h6"  align="left" style={{width:"100%",fontSize:'1.2em'}}>
                  Перечень товаров сопровождается аналитической информацией
                </Typography>
              </Grid>

              <Grid item xs={(!match1)?9:12} style={{paddingLeft:'10px',position:'relative'}}>
                <div style={{position:'absolute', right:'1em',wifth:'100%',marginTop:'1em'}}>
                  <Typography align="right" color="textSecondary" style={{fontSize:'0.8em'}}>
                    0₽ - 100₽
                  </Typography>
                  <Typography align="right" color="textSecondary" style={{fontSize:'0.8em'}}>
                    5 - 30 запр/мес
                  </Typography>
                </div>
                <TableContainer component={Paper} style={{width: '100%', backgroundColor:grey[800],padding:'1em 1em 1em 1em', height:'38em'}}>
                  <Table size="small" style={{marginTop:'1.5em'}}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tablecell}>Артикул</TableCell>
                        <TableCell className={classes.tablecell}>Бренд</TableCell>
                        <TableCell className={classes.tablecell}>Тип управления</TableCell>
                        <TableCell className={classes.tablecell} align="center">ОстМесНазад</TableCell>
                        <TableCell className={classes.tablecell} align="center">Ост</TableCell>
                        <TableCell className={classes.tablecell} align="center">Себест</TableCell>
                        <TableCell className={classes.tablecell} align="center">Лиды30</TableCell>
                        <TableCell className={classes.tablecell} align="center">Прд_30_60</TableCell>
                        <TableCell className={classes.tablecell} align="center">Прд_0_30</TableCell>
                        <TableCell className={classes.tablecell} align="center">Мрж_30_60</TableCell>
                        <TableCell className={classes.tablecell} align="center">Мрж_0_30</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        data.map(row=>{
                          return(
                            <TableRow>
                              <TableCell className={classes.tablecell}>{row.pin}</TableCell>
                              <TableCell className={classes.tablecell}>{row.brand}</TableCell>
                              <TableCell className={classes.tablecell}>{row.mt}</TableCell>
                              <TableCell className={classes.tablecell} align="center">{row.st_ma}</TableCell>
                              <TableCell className={classes.tablecell} align="center">{row.st}</TableCell>
                              <TableCell className={classes.tablecell} align="center">{row.pc}</TableCell>
                              <TableCell className={classes.tablecell} align="center">{row.lid}</TableCell>
                              <TableCell className={classes.tablecell} align="center">{row.s30}</TableCell>
                              <TableCell className={classes.tablecell} align="center">{row.s0}</TableCell>
                              <TableCell className={classes.tablecell} align="center">{row.m30}</TableCell>
                              <TableCell className={classes.tablecell} align="center">{row.m0}</TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Container>
        </div>



        <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '6.0vh', paddingTop: '4.0vh'}}>
          <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh'}}>
            Детализация автомата
          </Typography>

          <Grid container>

            <Grid item xs={(!match1)?4:12} style={{padding:'1em', marginTop:'2.0vh'}}>
              <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.2em'}}>
                По умолчанию управление ценой артикула происходит автоматически. Кликнув по артикулу в списке товаров можно вызвать панель с детальной информацией.
              </Typography>
            </Grid>


            <Grid item xs={(!match1)?8:12} style={{marginTop:'2.0vh'}}>
              <div style={{backgroundColor:grey[900], padding:'1em', position:'relative'}}>
                <div style={{position:'absolute', right:'1em'}}>
                  <Typography align="right" color="textSecondary" style={{fontSize:'0.8em', marginTop:'-0.5em'}}>
                    Артикул: SA520305C
                  </Typography>
                  <Typography align="right" color="textSecondary" style={{fontSize:'0.8em', marginTop:'1.5em'}}>
                    Бренд: Sabo
                  </Typography>
                </div>

                <div style={{display:'flex', flexDirection:'row', marginTop:'0.5em'}}>
                  <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.2em'}}>
                    Управление ценой артикула
                  </Typography>
                </div>

                <div style={{display:'flex', flexDirection:'row', marginTop:'1.5em', flexWrap:'wrap'}}>
                  <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                    Тип управления:
                  </Typography>
                  <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginLeft:'2em'}}>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      автомат
                    </Typography>
                    <Switch size="small" checked={false} color='primary'/>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      ручной
                    </Typography>
                  </div>
                </div>

                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>

                  <div style={{backgroundColor:grey[800], padding:'0.5em', marginLeft:'1em', marginTop:'1em'}}>
                    <div style={{display:'flex', flexDirection:'row'}}>
                      <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                        Текущая цена:
                      </Typography>
                      <Textfield style={{width: '5em', marginLeft:'1em'}} value={"92,4₽"} InputProps={{classes: { input: classes.textfield }}}/>
                    </div>

                    <div style={{display:'flex', flexDirection:'row'}}>
                      <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                        Автор:
                      </Typography>
                      <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em', textDecoration:'underline', marginLeft:'0.5em'}}>
                        system
                      </Typography>
                    </div>

                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      Маржинальная наценка: 0,8%
                    </Typography>

                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      Последнее изменение: 13.11.2020 14:15
                    </Typography>

                  </div>

                  <div style={{backgroundColor:grey[800], padding:'0.5em', marginLeft:'1em', marginTop:'1em'}}>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      Себестоимость: 91,6₽
                    </Typography>
                  </div>


                  <div style={{backgroundColor:grey[800], padding:'0.5em', marginLeft:'1em', marginTop:'1em'}}>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      Счетчик лидов: 17
                    </Typography>

                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      До снижения цены осталось запросов: 3
                    </Typography>

                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      Следующая цена: 91,0₽
                    </Typography>
                  </div>


                  <div style={{backgroundColor:grey[800], padding:'0.5em', marginLeft:'1em', marginTop:'1em'}}>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      Последняя сделка: 10.04.20
                    </Typography>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      Цена в последней сделке: 95,8₽
                    </Typography>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                      Цена зафиксирована до: -
                    </Typography>
                  </div>

                </div>

                
              </div>

            </Grid>

            
          </Grid>


        </Container>





        <div style={{backgroundColor:grey[900], width:'100%'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '6.0vh', paddingTop: '4.0vh'}}>
            <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh'}}>
              Ручное управление
            </Typography>

            <Grid container style={{marginTop:'4em'}}>


              <Grid item xs={(!match1)?4:12} style={{padding:'1em'}}>
                <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.2em'}}>
                  Переведите тумблер в ручной режим на определенный срок действия, если заранее знаете рыночную цену. После истечения указанного срока, артикул снова переходит в автоматический режим.
                </Typography>
              </Grid>


              <Grid item xs={(!match1)?8:12}>
                <div style={{backgroundColor:grey[800], padding:'1em', position:'relative'}}>
                  <div style={{position:'absolute', right:'1em'}}>
                    <Typography align="right" color="textSecondary" style={{fontSize:'0.8em', marginTop:'-0.5em'}}>
                      Артикул: SA520305C
                    </Typography>
                    <Typography align="right" color="textSecondary" style={{fontSize:'0.8em', marginTop:'1.5em'}}>
                      Бренд: Sabo
                    </Typography>
                  </div>

                  <div style={{display:'flex', flexDirection:'row', marginTop:'0.5em'}}>
                    <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1.2em'}}>
                      Управление ценой артикула
                    </Typography>
                  </div>

                  <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>

                    <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', marginTop:'1.5em'}}>
                      <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                        Тип управления:
                      </Typography>
                      <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginLeft:'2em'}}>
                        <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                          автомат
                        </Typography>
                        <Switch size="small" checked={true} color='primary'/>
                        <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                          ручной
                        </Typography>
                      </div>
                    </div>

                    <div style={{marginLeft:'2em'}}>
                      <div style={{display:'flex', flexDirection:'row'}}>
                        <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                          Срок:
                        </Typography>
                        <Textfield style={{width: '3em', marginLeft:'1em'}} value={"57"} InputProps={{classes: { input: classes.textfield2 }}}/>
                        <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em', marginLeft:'1em'}}>
                          дней
                        </Typography>
                      </div>

                      <div style={{display:'flex', flexDirection:'row'}}>
                        <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                          Автор:
                        </Typography>
                        <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em', textDecoration:'underline', marginLeft:'0.5em'}}>
                          Ковальков А.И.
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', marginTop:'1.5em'}}>

                    <div style={{backgroundColor:grey[800], padding:'0.5em', marginLeft:'1em', marginTop:'1em'}}>
                      <Typography component="h6" variant="h6"  align="center" style={{fontSize:'1em'}}>
                        Фиксированная цена
                      </Typography>
                      <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                        <div>
                          <RadioGroup value={0} style={{marginTop:'1em'}}>
                            <FormControlLabel classes={{label:classes.radiolabel}} control={<Radio size="small" value={0} color="primary"/>} label="в Рублях" />
                          </RadioGroup>
                          <Textfield style={{width: '5em', marginLeft:'1em',marginTop:'1em'}} value={"88 ₽"} InputProps={{classes: { input: classes.textfield2 }}}/>
                        </div>

                        <div style={{marginLeft:'1em'}}>
                          <RadioGroup value={0} style={{marginTop:'1em'}}>
                            <FormControlLabel classes={{label:classes.radiolabel}} control={<Radio size="small" color="primary"/>} label="в Евро" />
                          </RadioGroup>
                          <Textfield style={{width: '5em', marginLeft:'1em',marginTop:'1em'}} value={"0,98 €"} InputProps={{classes: { input: classes.textfield2 }}}/>
                        </div>
                      </div>
                    </div>

                    <div style={{backgroundColor:grey[800], padding:'0.5em', marginLeft:'1em', marginTop:'1em'}}>
                      <Typography component="h6" variant="h6"  align="center" style={{fontSize:'1em'}}>
                        Индивидуальный автомат
                      </Typography>
                      <RadioGroup value={0} style={{marginTop:'1em'}}>
                        <FormControlLabel classes={{label:classes.radiolabel}} control={<Radio size="small" color="primary"/>} label="индивидуальный автомат" />
                      </RadioGroup>
                      <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em',marginTop:'1em'}}>
                        Количество лидов до очередного
                      </Typography>
                      <div style={{display:'flex', flexDirection:'row'}}>
                        <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                          снижения цены:
                        </Typography>
                        <Textfield style={{width: '3em', marginLeft:'1em'}} value={20} InputProps={{classes: { input: classes.textfield2 }}}/>
                      </div>

                      <div style={{display:'flex', flexDirection:'row', marginTop:'1em'}}>
                        <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                          Шаг снижения цены:
                        </Typography>
                        <Textfield style={{width: '3em', marginLeft:'1em'}} value={"-1,5%"} InputProps={{classes: { input: classes.textfield2 }}}/>
                      </div>

                      <div style={{display:'flex', flexDirection:'row', marginTop:'1em'}}>
                        <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                          Минимальная наценка:
                        </Typography>
                        <Textfield style={{width: '3em', marginLeft:'1em'}} value={"-40%"} InputProps={{classes: { input: classes.textfield2 }}}/>
                      </div>

                      <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em', marginTop:'1em'}}>
                        После сделки цена фиксируется
                      </Typography>
                      <div style={{display:'flex', flexDirection:'row'}}>
                        <Typography component="h6" variant="h6"  align="left" style={{fontSize:'1em'}}>
                          на дней:
                        </Typography>
                        <Textfield style={{width: '3em', marginLeft:'1em'}} value={20} InputProps={{classes: { input: classes.textfield2 }}}/>
                      </div>
                    </div>

                  </div>

                  

                  
                </div>
              </Grid>

              

            </Grid>
          </Container>
        </div>
      </main>
      <Footer width={props.width}/>
      <LeftOrderDialog
        phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
        email={email} setEmail={setEmail}
        service={service} setService={setService}
        open={openDialog} setOpen={setOpenDialog} 
        handleSend={handleSend}
        chooseService={false}
      />
      <MessageDialog open={openMessageDialog} setOpen={setOpenMessageDialog} message="Благодарим за оставленную заявку. Бизнес-эксперт свяжется с Вами в ближайшее время."/>
    </React.Fragment>
  )
}

export default withWidth()(NonsellerPriceManagement)
