import React from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Textfield from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Arrow from '@elsdoerfer/react-arrow'
import ReactSpeedometer from "react-d3-speedometer"
import YouTube from 'react-youtube'

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'

import withWidth from '@material-ui/core/withWidth'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Header from '../components/header'
import ChatWidget from '../components/chat-widget'
import PhoneCall from '../components/phone-call'
import Footer from '../components/footer'

import stockManagement from '../images/stock-management.png'
import logo from '../images/retair_slogan.png'
import russianFlag from '../images/russian-flag.png'
import chinaFlag from '../images/china-flag.jpg'
import turkishFlag from '../images/turkish-flag.jpg'
import europeanUnionFlag from '../images/european-union.jpg'
import accountingSystem from '../images/accounting-system.jpg'

import { makeStyles } from '@material-ui/core/styles'
import { grey, indigo, green, red, yellow,orange } from '@material-ui/core/colors'

import MessageDialog from '../components/message-dialog'
import LeftOrderDialog from '../components/left-order'
import {getChatToken, setChatToken} from '../components/chat-widget/token.js'


function EconomiEffectCard(props) {

  return (
    <React.Fragment>
      <div style={{backgroundColor:indigo[500], padding:'0.5em',display:'flex', flexDirection:'column', marginTop:'0.5em'}}>
        <Typography component="h2" variant="h2" style={{fontSize:'1em'}}>
          {props.name}
        </Typography>
        <Typography style={{fontSize:'2em'}}>
          {props.value}
        </Typography>
      </div>
    </React.Fragment>
  )
}

function StockManagement(props) {

  document.title = 'Retair - АвтоЗаказ'

  const useStyles = makeStyles((theme) => ({

    logo: {
      opacity: '0.8',
      top: theme.spacing(10),
      cursor:'pointer',
      zIndex: '1',
      position: 'absolute'
    },
    fastWinCaption: {
      color: 'white',
      marginTop: theme.spacing(2),
    },
    forwho: {
      width: '20em',
      backgroundColor: grey[800],
      marginTop: '1vh',
      marginBottom: '3vh',
      padding: '1em',
      marginLeft: '1.5em'
    }


  }))

  const classes = useStyles()

  const [logoCoefficient, setLogoCoefficient] = React.useState(0.0)
  const match1 = useMediaQuery('(max-width:950px)')
  const match2 = useMediaQuery('(max-width:1120px)')
  const match3 = useMediaQuery('(max-width:1200px)')
  const match4 = useMediaQuery('(max-width:1300px)')

  const [openDialog, setOpenDialog] = React.useState(false)
  const [openMessageDialog,setOpenMessageDialog] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [service, setService] = React.useState('')

  React.useEffect(()=>{
    if(!match4) setLogoCoefficient(1.0)
    else if(!match3) setLogoCoefficient(0.9)
    else if(!match2) setLogoCoefficient(0.8)
    else if(!match1) setLogoCoefficient(0.6)
    else setLogoCoefficient(0)
  },[match1,match2,match3])

  const handleSend = () => {
    setOpenDialog(false)
    fetch('/send-message/', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({token: getChatToken(),message: 'Оставил заявку на рассказ бизнес-эксперта о приложении Управление запасами. Мои контакты: электронная почта - '+email+', телефон - '+phoneNumber}),
        json: true,
    })
    setOpenMessageDialog(true)
  }

  return (
    <React.Fragment>
      <Header width={props.width} title=''/>
      <ChatWidget width={props.width}/>
      <PhoneCall/>
      <main style={{marginTop:'6em'}}>


        <div className={classes.heroContent} style={{backgroundImage: 'url(' + stockManagement + ')', backgroundPosition:'center'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '8.0vh', paddingTop: (!match1)?'1.5vh':'4.0vh'}}>
            <Typography component="h1" align="center" color="textPrimary" gutterBottom style={{color:'white',marginTop:'3vh',fontSize:(match1)?'1.9em':'2.5em'}}>
              Retair.АвтоЗаказ
            </Typography>
            <Grid container>
              <Grid item xs={(match1)?4:6}/>
              <Grid item xs={(match1)?8:6} style={{marginTop:'3vh'}}>
                <Typography component="h1" variant="h5" align="left" color="textPrimary" gutterBottom style={{color:'white'}}>
                  Управление запасами
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={(match1)?4:6}/>
              <Grid item xs={(match1)?8:6}>
                <Typography variant="h6" align="left" color="textSecondary" paragraph style={{color:'white',fontSize:'1em'}}>
                  Без излишков, с постоянным наличием и с заранее заданными KPI
                </Typography>
              </Grid>
              <Grid item xs={6}/>
              <Grid item xs={6}>
                <div>
                  <Grid container spacing={2} justify="left">
                    <Grid item>
                      <Button variant="contained" color="primary"
                        onClick={e=>setOpenDialog(true)}
                      >
                        Оставить заявку
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <img src={logo} className={classes.logo} alt={"Логотип Retair"}
          style={{
            width: logoCoefficient*22 + 'em',
            height: logoCoefficient*11 + 'em',
            marginBottom: 1 + 'em',
            marginTop: 4 + 'em',
            marginLeft: logoCoefficient*8 + 'em',
          }}
        />




        <div style={{backgroundColor:grey[900], width:'100%'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '2.0vh', paddingTop: '2.0vh'}}>
            <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh'}}>
              Для кого данное решение?
            </Typography>
            <div style={{width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:'center',alignItems:'space-between'}}>

              <div className={classes.forwho}>
                <Typography component="h1" variant="h1" align="center" style={{fontSize:'1.5em'}}>
                  Рознично-оптовые сети
                </Typography>
                <Typography component="h1" variant="h1" align="center" style={{fontSize:'1.5em'}}>
                  (сеть магазинов и СТО)
                </Typography>
                <div style={{width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:'flex-start',alignItems:'center'}}>
                  <Typography component="h1" variant="h1" align="center" style={{fontSize:'1.5em',marginLeft:'2em'}}>
                    закупки в:
                  </Typography>
                  <Avatar src={russianFlag} variant="square" style={{width:'1.3em', height:'0.8em', marginLeft:'1em'}}/>
                </div>
                <Typography component="h2" variant="h2" align="center" style={{fontSize:'1em'}}>
                  (склады >= 1, ассортимент > 1 000)
                </Typography>
                <Divider style={{marginTop:'1em'}}/>
                <Typography align="left" color="textSecondary" style={{fontSize:'0.8em', marginTop:'0.25em'}}>
                  Финансовый эффект
                </Typography>
                <div style={{width:'100%',display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:'center',alignItems:'space-between'}}>
                  <div style={{padding:'0.5em',display:'flex', flexDirection:'column'}}>
                    <EconomiEffectCard name='Операционные издержки' value='-50%' />
                    <EconomiEffectCard name='Товарный запас' value='-10% -20%' />
                    <EconomiEffectCard name='Наличие' value='96%+' />
                  </div>
                </div>
                <Divider style={{marginTop:'1em'}}/>
                <Typography align="left" color="textSecondary" style={{fontSize:'0.8em', marginTop:'0.25em'}}>
                  Функционал
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'1em'}}>
                  Точно посчитает сколько заказать на склад
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'1em'}}>
                  Точно посчитает сколько переместить на дочерный склад
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'1em'}}>
                  Автоматически
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'0.1em', marginLeft:'1em'}}>
                  Проценит у поставщиков
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'0.1em', marginLeft:'1em'}}>
                  Создаст в 1С заказ поставщику
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'0.1em', marginLeft:'1em'}}>
                  Отправит заказ поставщику
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'0.1em', marginLeft:'1em'}}>
                  Сформирует перемещения
                </Typography>
              </div>


              <div className={classes.forwho} style={{position:'relative'}}>
                <Typography component="h1" variant="h1" align="center" style={{fontSize:'1.5em'}}>
                  Рознично-оптовые сети
                </Typography>
                <Typography component="h1" variant="h1" align="center" style={{fontSize:'1.5em'}}>
                  (сеть магазинов и СТО)
                </Typography>
                <div style={{width:'100%', display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:'flex-start',alignItems:'center'}}>
                  <Typography component="h1" variant="h1" align="center" style={{fontSize:'1.5em',marginLeft:'1.5em'}}>
                    закупки в:
                  </Typography>
                  <Avatar src={russianFlag} variant="square" style={{width:'1.3em', height:'0.8em', marginLeft:'0.15em'}}/>
                  <Avatar src={chinaFlag} variant="square" style={{width:'1.3em', height:'0.8em', marginLeft:'0.15em'}}/>
                  <Avatar src={turkishFlag} variant="square" style={{width:'1.3em', height:'0.8em', marginLeft:'0.15em'}}/>
                  <Avatar src={europeanUnionFlag} variant="square" style={{width:'1.5em', height:'0.8em', marginLeft:'0.15em'}}/>
                </div>
                <Typography component="h2" variant="h2" align="center" style={{fontSize:'1em'}}>
                  (склады >= 2, ассортимент > 3 000)
                </Typography>
                <Divider style={{marginTop:'1em'}}/>
                <Typography align="left" color="textSecondary" style={{fontSize:'0.8em', marginTop:'0.25em'}}>
                  Финансовый эффект
                </Typography>
                <div style={{width:'100%',display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:'center',alignItems:'space-between'}}>
                  <div style={{padding:'0.5em',display:'flex', flexDirection:'column'}}>
                    <EconomiEffectCard name='Операционные издержки' value='-50%' />
                    <EconomiEffectCard name='Оборачиваемость' value='-25%' />
                    <EconomiEffectCard name='Наличие' value='95%' />
                  </div>
                </div>
                <Divider style={{marginTop:'1em'}}/>
                <Typography align="left" color="textSecondary" style={{fontSize:'0.8em', marginTop:'0.25em'}}>
                  Функционал
                </Typography>
                <KeyboardArrowRightIcon style={{position:'absolute', left:'0', top:'23em', height:'2em',width:'2em'}}/>
                <KeyboardArrowLeftIcon style={{position:'absolute', left:'9.5em', top:'23em', height:'2em',width:'2em'}}/>
                <Typography align='center' style={{fontSize:'1em', marginTop:'8em', marginBottom:'8em'}}>
                  Объединяет функционал российских оптово-розничных сетей и дистрибьюторов
                </Typography>
              </div>


              <div className={classes.forwho}>
                <Typography component="h1" variant="h1" align="center" style={{fontSize:'1.5em'}}>
                  Крупный оптовый бизнес
                </Typography>
                <Typography component="h1" variant="h1" align="center" style={{fontSize:'1.5em'}}>
                  (дистрибьюторы, маркетплейсы)
                </Typography>
                <Typography component="h2" variant="h2" align="center" style={{fontSize:'1em'}}>
                  (склады >= 5, ассортимент > 30 000)
                </Typography>
                <Divider style={{marginTop:'1em'}}/>
                <Typography align="left" color="textSecondary" style={{fontSize:'0.8em', marginTop:'0.25em'}}>
                  Финансовый эффект
                </Typography>
                <div style={{width:'100%',display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:'center',alignItems:'space-between'}}>
                  <div style={{padding:'0.5em',display:'flex', flexDirection:'column'}}>
                    <EconomiEffectCard name='Штат аналитиков' value='-50%' />
                    <EconomiEffectCard name='Оборачиваемость' value='-25%' />
                    <EconomiEffectCard name='Уровень сервиса' value='90% - 95%' />
                  </div>
                </div>
                <Divider style={{marginTop:'1em'}}/>
                <Typography align="left" color="textSecondary" style={{fontSize:'0.8em', marginTop:'0.25em'}}>
                  Функционал
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'1em'}}>
                  Сформирует заказ поставщику согласно календарю заказов
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'1em'}}>
                  Учтет все условия поставщика: минимальная сумма заказа, объем и прочее
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'1em'}}>
                  Сформирует перемещения на дочерные склады
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'1em'}}>
                  Доукомплектует товары с мин. комплектностью
                </Typography>
                <Typography align='left' style={{fontSize:'1em', marginTop:'1em'}}>
                  Сформирует годовой бюджет закупок
                </Typography>
              </div>

            </div>

          </Container>
        </div>

        



        <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '3.0vh', paddingTop: '3.0vh'}}>
          <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '1.0vh'}}>
            Управление от KPI
          </Typography>
          <div style={{marginLeft:'auto',width:'300px', display:'flex', flexDirection: 'row',marginBottom: '1.0vh'}}>
            <blockquote style={{margin:'0 20px 0 0px'}}><i style={{fontSize:'3em'}}>"</i></blockquote>
            <blockquote style={{margin:'0',fontSize:'0.8em', textAlign:'left', borderLeft:'1px solid white', paddingLeft:'1em'}}>
              <i>
              Цифра определяет цель. Технологии - это эффективный метод ее достижения, а люди должны быть перепрофилированы на более сложные задачи, где их экспертиза даст больший результат, чем от Excel-формул. 
              </i>
            </blockquote>
          </div>
          <Typography align="left" style={{marginBottom: '1.0vh'}}>
            Все приложения платформы, в том числе и Retair.Автозаказ, управляются сверху вниз в зависимости от целей Заказчика,  мы твердо убеждены в том, что других принципов управления в бизнесе и не должно быть, только целевые показатели и получаемый результат от проделанной работы. 
          </Typography>
          <Typography align="left" style={{marginBottom: '0vh'}}>
            На примере приложения Retair.Автозаказ целеполагание строится на 2-х главных  параметрах:
          </Typography>
          <ul style={{textAlign:'left', marginTop:'0.1em'}}>
            <li><Typography>Плановая оборачиваемость</Typography></li>
            <li><Typography>Уровень сервиса (наличие товара на складе)</Typography></li>
          </ul>
          <div style={{width:'100%', display:'flex', flexDirection: 'row', flexWrap:'wrap', justifyContent:'center', alignItems:'space-around', paddingTop: '2.0vh'}}>
            <div style={{width:'350px', height:'630px', backgroundColor:grey[800], marginTop:'2vh'}}>
              <Typography align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh', fontSize:'1.35em'}}>
                Ваша настройка
              </Typography>

              <div style={{backgroundColor:indigo[800], padding:'20px', height:'250px'}}>
                <Typography align="center" style={{marginBottom:'20px'}}>
                  Оборачиваемость, дн
                </Typography>
                <ReactSpeedometer
                  maxValue={90}
                  value={42}
                  segments={9}
                  segmentColors={[
                    red[400],
                    red[300],
                    orange[400],
                    green[300],
                    green[500],
                    green[300],
                    orange[400],
                    red[300],
                    red[400]
                  ]}
                />
              </div>

              <div style={{backgroundColor:indigo[800], padding:'20px', height:'250px', marginTop:'20px'}}>
                <Typography align="center" style={{marginBottom:'20px'}}>
                  Уровень сервиса, %
                </Typography>
                <ReactSpeedometer
                  maxValue={100}
                  value={95}
                  segments={5}
                  segmentColors={[
                    red[400],
                    red[300],
                    orange[400],
                    green[300],
                    green[500]
                  ]}
                />
              </div>
            </div>

            <div style={{width:'200px', height:'630px', marginTop:'2vh'}}>
              <Typography align="center" className={classes.fastWinCaption} style={{marginTop: '200px', fontSize:'1.35em'}}>
                Система сама транслирует настройки в шт.
              </Typography>
              <div style={{width:'100%', marginTop:'50px', marginLeft:'25px', position:'relative'}}>
                <div style={{width:'100px',height:'2px',backgroundColor:'white'}}/>
                <div style={{width:'100px',height:'2px',backgroundColor:'white', marginTop:'30px'}}/>
                <div style={{left:'100px', top:'-53px',position:'absolute',width:'50px',height:'2px',backgroundColor:'white', marginTop:'50px', transform:'rotate(45deg)'}}/>
                <div style={{left:'100px', top:'-17px',position:'absolute',width:'50px',height:'2px',backgroundColor:'white', marginTop:'50px', transform:'rotate(-45deg)'}}/>
              </div>
              <div style={{backgroundColor:grey[800], width:'120px', padding:'10px', marginTop:'30px', marginLeft:'50px'}}>
                <Typography align="center" style={{fontSize:'12px'}}>
                  Прогноз продаж
                </Typography>
                <Typography align="center" style={{fontSize:'16px'}}>
                  0,5 шт./дн
                </Typography>
              </div>
            </div>

            <div style={{width:'200px', height:'630px', backgroundColor:grey[800], marginTop:'2vh'}}>
              <Typography align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh', fontSize:'1.35em'}}>
                Полученный результат
              </Typography>
              <div style={{backgroundColor:indigo[800], padding:'20px', height:'160px', marginTop:'180px'}}>
                <Typography align="center" style={{fontSize:'2em'}}>
                  21 шт
                </Typography>
                <Typography align="center" style={{fontSize:'1em'}}>
                  постоянно поддерживаются на складе
                </Typography>
              </div>
            </div>

          </div>

          <Typography align="left" style={{marginBottom: '2vh',marginTop: '2vh'}}>
            Найти правильный баланс между представленными выше показателями задача нетривиальная, и когда в очередной красивой презентации вам показывают увлекательные слайды и рассказывают математические алгоритмы с применением космических сил, то на вопрос на сколько поменяются показатели Наличия и Оборачиваемости для Вашей компании, ответить никто не может, либо получите  ответ, несодержащий конкретики.
          </Typography>
          <Typography align="left" style={{marginBottom: '0vh'}}>
            Мы сразу можем их озвучить  без помощи слайдов и прочего:
          </Typography>
          <ul style={{textAlign:'left',marginBottom: '2.0vh', marginTop:'0.1em'}}>
            <li><Typography>Оборачиваемость в днях снизится не меньше чем на 15%</Typography></li>
            <li><Typography>Уровень сервиса будет гарантированно выше 90%</Typography></li>
          </ul>

          <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <div style={{width:'500px', backgroundColor:grey[800], padding:'1em'}}>
              <Typography align="center" style={{fontSize:'1.5em', marginBottom:'0.5em'}}>
                Если Вы хотите, чтобы Ваш процесс закупок измерялся только цифрами, а не исключительными историями экспертов в закупках, то Вы получите обещаннные показатели, первый шаг, который Вам нужно сделать - нажать кнопку Оставить заявку.
              </Typography>
              <Button variant="contained" color="primary"
                onClick={e=>setOpenDialog(true)}
              >
                Оставить заявку
              </Button>
            </div>
          </div>
        </Container>




        <div style={{backgroundColor:grey[900], width:'100%'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '2.0vh', paddingTop: '2.0vh'}}>
            <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh'}}>
              Пример внедрения для сети магазинов и СТО
            </Typography>
            <YouTube
              videoId="_DJlSV5SD3c"
            />
          </Container>
        </div>
        

      </main>
      <Footer width={props.width}/>
      <LeftOrderDialog
        phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
        email={email} setEmail={setEmail}
        service={service} setService={setService}
        open={openDialog} setOpen={setOpenDialog} 
        handleSend={handleSend}
        chooseService={false}
      />
      <MessageDialog open={openMessageDialog} setOpen={setOpenMessageDialog} message="Благодарим за оставленную заявку. Бизнес-эксперт свяжется с Вами в ближайшее время."/>
    </React.Fragment>
  )
}

export default withWidth()(StockManagement)
