import React, {useContext} from 'react'

import { BrowserRouter, Route } from 'react-router-dom'

import MainPage from './main-page'
import Busy from './busy'
import Contacts from './contacts'
import Integration1C from './integration-1c'
import NonSellerPriceManagement from './nonseller-price-management'
import StockManagement from './stock-management'
import StockBalance from './stock-balance'

function BusySB(props) {return (<Busy name='Retair - Балансировка'/>)}
function BusyES(props) {return (<Busy name='Retair - Интернет-магазин'/>)}
function BusyI1C(props) {return (<Integration1C/>)}

function Site(props) {
  
  return (
    <React.Fragment>
      <Route exact path='/' component={MainPage}/>
      <Route exact path='/stock-management' component={StockManagement}/>
      <Route exact path='/stock-balancing' component={StockBalance}/>
      <Route exact path='/nonseller-price-management' component={NonSellerPriceManagement}/>
      <Route exact path='/ecommerce-site' component={BusyES}/>
      <Route exact path='/integration-1c' component={BusyI1C}/>
      <Route exact path='/contacts' component={Contacts}/>
    </React.Fragment>
  )
}

export default Site
