import React from 'react'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Textfield from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import withWidth from '@material-ui/core/withWidth'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Header from '../components/header'
import ChatWidget from '../components/chat-widget'
import Footer from '../components/footer'
import MessageDialog from '../components/message-dialog'

import { makeStyles } from '@material-ui/core/styles'

import {getChatToken, setChatToken} from '../components/chat-widget/token.js'

function Busy(props) {

  document.title = props.name

  const useStyles = makeStyles((theme) => ({

    fastWinCaption: {
      color: 'white',
      marginTop: theme.spacing(2),
    },
    email: {
      color: 'white',
      marginTop: theme.spacing(2),
      fontSize:'1em'
    },


  }))

  const classes = useStyles()

  const [email,setEmail] = React.useState('')
  const [openMessageDialog, setOpenMessageDialog] = React.useState(false)

  React.useEffect(()=>{
    setChatToken()
  },[])


  const handleNewUserMessage = (event) => {
    fetch('/send-message/', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({token: getChatToken(),message: 'Отправьте мне пожалуйста уведомление на почту '+email+' как заполните страницу '+props.name}),
        json: true,
    })
    setOpenMessageDialog(true)
    setEmail('')
  }

  return (
    <React.Fragment>
      <Header width={props.width}/>
      <ChatWidget width={props.width}/>
      <main style={{marginTop:'6em'}}>
      	<Container maxWidth="md" style={{textAlign:'center', paddingBottom: '5.0vh', paddingTop: '5.0vh'}}>
          <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption}>
            Мы пока не успели заполнить эту страницу, поскольку очень заняты внедрением сервисов клиентам
          </Typography>
          <Typography component="h6" variant="h6" align="center" className={classes.email}>
            Оставьте свой адрес электронной почты, и мы вам отправим уведомление, как только заполним страницу
          </Typography>
          <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
	        <Textfield
	          label="Электронная почта"
	          variant="outlined"
	          style={{marginTop: '1em',}}
	          value={email}
	          onChange={(e) => setEmail(e.target.value)}
	        />
	        <Button color="primary" variant="contained" style={{marginTop: '1em',}}
	        	onClick={handleNewUserMessage}
	        >
	          Отправить
	        </Button>
	      </div>
        </Container>
      </main>
      <Footer width={props.width}/>
      <MessageDialog open={openMessageDialog} setOpen={setOpenMessageDialog} message="Мы получили Ваш запрос на отправку уведомления, и обязательно его отправим, как только заполним страницу контентом."/>
    </React.Fragment>
  )
}

export default withWidth()(Busy)
