import React, {useContext} from 'react'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

import { useHistory } from 'react-router-dom'

function ProductCard(props) {
  const history = useHistory()

  const useStyles = makeStyles((theme) => ({

    root: {
      width: 345,
    },
    media: {
      height: 140,
    },

  }))

  const classes = useStyles()

  return (
    <Card className={classes.root}
      style={{
        backgroundColor:grey[700],
        marginLeft: props.marginLeft,
        marginBottom: props.marginBottom,
        marginRight:props.marginRight,
        marginTop:props.marginTop
      }}
    >
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.image}
        />
        <CardContent>
          <Typography component="h6" variant="h6"  align="center" style={{width:"100%"}}>
            {props.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button variant="contained" color="primary" onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' }); history.push('/'+props.link)}}>
          Подробнее
        </Button>
      </CardActions>
    </Card>
  );
}

export default ProductCard
