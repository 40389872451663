const localStorageKey = '__chat_token__'
const tokenLength = 40

function generateAndSetChatToken(key,token) {
  window.localStorage.setItem(key, token)
}

// function logout() {
//   window.localStorage.removeItem(localStorageKey)
//   return Promise.resolve()
// }

function getChatToken() {
  return window.localStorage.getItem(localStorageKey)
}

function setChatToken() {
	if (!(localStorageKey in window.localStorage))
	{
		
		const rand=()=>Math.random(0).toString(36).substr(2);
		const token=(length)=>(rand()+rand()+rand()+rand()).substr(0,length);

		generateAndSetChatToken(localStorageKey, token(tokenLength))
	}

}

export {setChatToken, getChatToken}
