import React, {useContext} from 'react'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'

function PhoneCallDialog(props) {

	const { phoneNumber, setPhoneNumber ,message, open, setOpen, handleCall, ...otherProps } = props

	const handleClose = () => {
	    setOpen(false)
	};

	return (
		<Dialog
		    open={open}
		    onClose={handleClose}
			>
			<DialogContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<DialogContentText>
					Заказать обратный звонок и бесплатную консультацию бизнес-эксперта
				</DialogContentText>
		        <PhoneInput
			      placeholder="Телефон"
			      value={phoneNumber}
			      onChange={setPhoneNumber}
		          style={{marginTop: '1em', marginBottom:'2em'}}
		          defaultCountry="RU"
			    />
				<Button onClick={handleCall} color="primary" variant="contained">
					Заказать звонок
				</Button>
			</DialogContent>
		</Dialog>
	);
}

export default PhoneCallDialog
