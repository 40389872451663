import React, {useContext} from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'

function MessageDialog(props) {

	const { message, open, setOpen, ...otherProps } = props

	const handleClose = () => {
	    setOpen(false);
	};

	return (
		<Dialog
		    open={open}
		    onClose={handleClose}
			>
			<DialogContent>
				<DialogContentText>
					{message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" variant="contained">
				ОК
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default MessageDialog;
