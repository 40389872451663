import React, {useContext} from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import logoVertical from '../../images/retair_logo_vertical.png'
import whatsapp from '../../images/whatsapp.png'
import telegram from '../../images/telegram.png'
import email from '../../images/email.png'
import vk from '../../images/vk.png'
import facebook from '../../images/facebook.png'
import phone from '../../images/phone.png'

import { makeStyles } from '@material-ui/core/styles'

function Footer(props) {

  const useStyles = makeStyles((theme) => ({

    logoVertical: {
        width: theme.spacing(20),
        height: theme.spacing(30),
        margin: 'auto'
    },
    whatsapp: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0)
    },
    contact: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0)
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    typography: {
      marginTop: theme.spacing(2),
    },
  }))

  const classes = useStyles()

  
  
  return (
    <footer className={classes.footer}>
      
      <Container maxWidth="md">
        <Grid container spacing={3}>
          {
            (props.width=='xs'||props.width=='sm')?<React.Fragment/>:
              <Grid item xs={4}
                style={{
                  textAlign: 'center'
                }}
              >
                <img src={logoVertical} className={classes.logoVertical} alt={"Логотип Retair"}/>
              </Grid>
          }
          <Grid item xs={(props.width=='xs'||props.width=='sm')?12:4}>
            <Typography component="h6" variant="h6" align="center" style={{color:'white'}}>
              Как с нами связаться
            </Typography>

            <div style={{display:'flex', flexDirection:'row', alignItems: 'center', justifyContent:'flex-start'}}>
              <a href="https://wa.me/79653298288">
                <img src={whatsapp} className={classes.whatsapp} alt={"whatsapp"}/>
              </a>
              <a href="https://t.me/retair">
                <img src={telegram} className={classes.whatsapp} alt={"telegram"}/>
              </a>

              <Typography style={{color:'white', fontSize:'1.0em'}}>
                Задайте свой вопрос нам в мессенджерах
              </Typography>
            </div>

            <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em'}}>
              Общение с клиентами - это самая важная для нас часть работы
            </Typography>
            
            <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em'}}>
              Мы будем рады вашему звонку, письму на почту, либо сообщению в мессенджере.
            </Typography>
            
            <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em'}}>
              Наши специалисты мгновенно рассмотрят ваше обращение и оперативно вам помогут
            </Typography>
          </Grid>
          <Grid item xs={(props.width=='xs'||props.width=='sm')?12:4}>

            <Typography component="h6" variant="h6" align="center" style={{color:'white'}}>
              Контакты
            </Typography>

            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              <a href="tel:+78005005480" style={{display:'flex', flexDirection:'row', alignItems: 'center', justifyContent:'flex-end'}}>
                <img src={phone} className={classes.contact} alt={"phone"}/>
                <Typography style={{color:'white', fontSize:'1.0em'}}>
                  8 800 500 54 80
                </Typography>
              </a>
              <a href="mailto: retair.solutions@gmail.com" style={{display:'flex', flexDirection:'row', alignItems: 'center', justifyContent:'flex-end'}}>
                <img src={email} className={classes.contact} alt={"email"}/>
                <Typography style={{color:'white', fontSize:'1.0em'}}>
                  retair.solutions@gmail.com
                </Typography>
              </a>
              <a href="https://vk.com/retair" style={{display:'flex', flexDirection:'row', alignItems: 'center', justifyContent:'flex-end'}}>
                <img src={vk} className={classes.contact} alt={"vk"}/>
                <Typography style={{color:'white', fontSize:'1.0em'}}>
                  Retair
                </Typography>
              </a>
              <a href="https://www.facebook.com/RetairSaaS/" style={{display:'flex', flexDirection:'row', alignItems: 'center', justifyContent:'flex-end'}}>
                <img src={facebook} className={classes.contact} alt={"facebook"}/>
                <Typography style={{color:'white', fontSize:'1.0em'}}>
                  RetairSaaS
                </Typography>
              </a>
            </div>

          </Grid>
        </Grid>

      </Container>

    </footer>
  )
}

export default Footer
