import React,{useContext} from 'react'
import {FullPageSpinner} from './components/lib'
import {AuthContext} from './context/auth-context'

const loadAuthenticatedApp = () => import('./authenticated-app')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

function App() {
    const context = useContext(AuthContext)

    React.useEffect(() => {
        loadAuthenticatedApp()
    }, [])

    return (
        <React.Suspense fallback={<FullPageSpinner />}>
            {context.isAuth ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </React.Suspense>
    )
}

export default App
