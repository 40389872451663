import React from 'react'

import phone from '../../images/phone-white.png'

import PhoneCallDialog from '../phone-call-dialog'

import { makeStyles } from '@material-ui/core/styles'
import './phone-call-styles.css'

import {getChatToken, setChatToken} from '../chat-widget/token.js'
import MessageDialog from '../message-dialog'

function PhoneCall(props) {

  const useStyles = makeStyles((theme) => ({

    phone: {
      width: '3.4em',
      height: '3.4em',
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0),
      cursor:'pointer'
    },
    phoneDiv: {
      width: '5em',
      height: '5em',
      padding: '0.8em',
      borderRadius: '2.5em',
      position: 'fixed',
      left: 'calc(50% - 8em)',
      top: '50%',
      marginLeft: 'auto',
      zIndex: '3',
      backgroundColor: '#39B9E5',
      marginLeft: '50%',
    }

  }))

  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [openMessageDialog,setOpenMessageDialog] = React.useState(false)

  React.useEffect(()=>{
    setChatToken()
  },[])

  React.useEffect(()=>{
  	setTimeout(()=>{
  		setOpen(true)
	},6000)
  },[open])

  const handleCall = () => {
  	setOpenDialog(false)
  	fetch('/send-message/', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({token: getChatToken(),message: 'Прошу позвонить бизнес-эксперта по номеру '+phoneNumber+' для бесплатной консультации.'}),
        json: true,
    })
    setOpenMessageDialog(true)
    setPhoneNumber('')
  }
	  
  
  return (
  	<React.Fragment>
	  	{
	  		(open)?
			    <div className={classes.phoneDiv+' phone-call'}>
			    	<img src={phone} className={classes.phone} alt={"Значок телефона"}
			    		onClick={()=>setOpenDialog(true)}
			    	/>
			    </div>
			:
				<React.Fragment/>
		}
		<PhoneCallDialog handleCall={handleCall} open={openDialog} setOpen={setOpenDialog}
			phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
		/>
		<MessageDialog open={openMessageDialog} setOpen={setOpenMessageDialog} message="Бизнес-эксперт наберет Вам в течение часа в рабочее время с Пн по Пт с 9:00 до 18:00."/>
	</React.Fragment>
  )
}

export default PhoneCall


		