import React, {useEffect, useContext} from "react"

import {AuthContext} from './auth-context'
import {simpleCallRpc} from '../utils/api-client'

const UserContext = React.createContext()

function UserProvider(props) {
  const authContext = useContext(AuthContext)

  const [workspaces, setWorkspaces] = React.useState([])
  const [selected_workspace, setSelectedWorkspace] = React.useState('')
  const [applications, setApplications] = React.useState([])
  const [colleagues, setColleagues] = React.useState([])
  const [selectedApplicationOrColleague, setSelectedApplicationOrColleague] = React.useState('nothing')
  const [selectedApplication, setSelectedApplication] = React.useState('')
  const [selectedColleague, setSelectedColleague] = React.useState(0)

  useEffect(() => {
      if(selected_workspace!=='')
      {
        simpleCallRpc(authContext,'get_user_applications',{workspace_hash: selected_workspace})
          .then(result => setApplications(result)) 

        simpleCallRpc(authContext,'get_user_colleagues',{workspace_hash: selected_workspace})
          .then(result => setColleagues(result))    
      }
    },
    [selected_workspace]
  )

  return( 
    <UserContext.Provider 
      value={{
        workspaces:workspaces,
        setWorkspaces:setWorkspaces,
        selected_workspace:selected_workspace,
        setSelectedWorkspace:setSelectedWorkspace,
        applications:applications,
        setApplications:setApplications,
        colleagues:colleagues,
        setColleagues:setColleagues,
        selectedApplicationOrColleague:selectedApplicationOrColleague,
        setSelectedApplicationOrColleague:setSelectedApplicationOrColleague,
        selectedApplication:selectedApplication,
        setSelectedApplication:setSelectedApplication,
        selectedColleague:selectedColleague,
        setSelectedColleague:setSelectedColleague,
      }} {...props} 
    />
  )
}

export { UserProvider, UserContext };