import React, {useContext} from 'react'
import PropTypes from 'prop-types'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import logo from '../../images/retair_logo.png'
import logoSlogan from '../../images/retair_slogan.png'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import DataIntegrationIcon from '../../images/data-integration.png'
import InfoIcon from '@material-ui/icons/Info'
import Icon1C from '../../images/icon-1c.png'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

import { useHistory } from 'react-router-dom'

const menuItems = [
  {
    name: "Продукты",
    contentType: 'grouped',
    icon: ViewModuleIcon,
    iconType: 'material-ui-icon',
    items: [
      {
        groupName: 'Логистика и управление запасами',
        items: [
          {
            name: "Retair.Автозаказ",
            link: "stock-management",
            iconType: 'material-ui-icon',
            icon: FiberManualRecordIcon
          },
          {
            name: "Retair.Балансировка",
            link: "stock-balancing",
            iconType: 'material-ui-icon',
            icon: FiberManualRecordIcon
          }
        ]
      },
      {
        groupName: 'Маркетинг и управление продажами',
        items: [
          {
            name: "Retair.ЦеныНеликвидов",
            link: "nonseller-price-management",
            iconType: 'material-ui-icon',
            icon: FiberManualRecordIcon
          },
          {
            name: "Retair.Интернет-магазин",
            link: "ecommerce-site",
            iconType: 'material-ui-icon',
            icon: FiberManualRecordIcon
          }
        ]
      },
    ]
  },
  {
    name: "Интеграция",
    icon: DataIntegrationIcon,
    iconType: 'custom-icon',
    contentType: 'simple',
    link:"integration-1c",
    items: [
      {
        name:"Интеграция с 1С",
        link:"integration-1c",
        iconType: 'custom-icon',
        icon: Icon1C
      }
    ]
  },
  {
    name: "О компании",
    icon: InfoIcon,
    iconType: 'material-ui-icon',
    contentType: 'simple',
    items: [
      {
        name:"Контакты",
        link:'contacts',
        iconType: 'material-ui-icon',
        icon: ContactPhoneIcon
      },
      {
        name: "Вакансии",
        link:'#',
        iconType: 'material-ui-icon',
        icon: GroupAddIcon
      }
    ]
  }
]

function HeaderDesktop() {
  const history = useHistory()

  const useStyles = makeStyles((theme) => ({

    logo: {
      width: theme.spacing(10),
      height: theme.spacing(5),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(0),
      cursor:'pointer'
    },

    menuItem: {
      height: theme.spacing(11),
    }

  }))

  const classes = useStyles()

  const [openIntegration, setIntegration] = React.useState(false)
  const [submenuContent, setSubmenuContent] = React.useState({contentType: '', items:[]})
  const [chosenMenuItem, setChosenMenuItem] = React.useState('')

  const signInButtonClick = () => {
    history.push('/apps')
  }

  const toggleIntegration = (name,event,open) => {
    if(open)
      setIntegration(open)
    else 
    {
      if(typeof event.relatedTarget === 'object' && event.relatedTarget !== null)
      {
        if('className' in event.relatedTarget)
          if(name=='onMouseOut'&&!event.relatedTarget.className.includes('Retair-submenu-container'))
          {
            setChosenMenuItem('')
            setIntegration(open)
          }
      }
      else
      {
        setChosenMenuItem('')
        setIntegration(open)
      }
    }
  }
  
  return (
    <React.Fragment>
      <AppBar position="fixed" style={{backgroundColor:grey[900]}}>
        <Container maxWidth="md" 
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <img src={logo} className={classes.logo} alt={"Логотип Retair"} onClick={e=>history.push('/')}/>

          {
            menuItems.map(item => {
              return (
                <Link
                  href={item.link}
                  className={classes.menuItem}
                  style={{
                    display: 'block',
                    borderTop: '0.5em solid',
                    borderBottom: '0.5em solid',
                    borderBottomColor: (chosenMenuItem==item.name)?grey[800]:grey[900],
                    borderTopColor: (chosenMenuItem==item.name)?'#39B9E5':grey[900],
                    backgroundColor: (chosenMenuItem==item.name)?grey[800]:grey[900],
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '2em',
                    paddingRight: '2em'
                  }}
                  onMouseOut={(event)=>{
                    toggleIntegration('onMouseOut',event,false)
                  }}
                  onMouseOver={(event)=>{
                    toggleIntegration('onMouseOver',event,true)
                    setChosenMenuItem(item.name)
                    setSubmenuContent({contentType:item.contentType,items:item.items})
                  }}
                >
                  <Typography component="h6" variant="h6" style={{color:'white', fontSize:'1.2em'}}>
                    {item.name}
                  </Typography>
                </Link>
              )
            })
          }

          <Button onClick={signInButtonClick}>
            Авторизация
          </Button>

        </Container>
      </AppBar>
      {
        (openIntegration)?
          <AppBar position="fixed" style={{backgroundColor:grey[800], zIndex:'100'}}
            className='integration-appbar'
          >
            <Container maxWidth="md" 
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: (submenuContent.items.length > 1)?((submenuContent.contentType=='simple')?'space-around':'flex-start'):'space-around',
                alignItems: 'flex-start',
              }}
              onMouseOut={(event)=>{
                if(typeof event.relatedTarget === 'object' && event.relatedTarget !== null)
                {
                  if('className' in event.relatedTarget)
                    if(!event.relatedTarget.className.includes('Retair-submenu-container'))
                    {
                      setChosenMenuItem('')
                      setIntegration(false)
                    }
                }
              }}
              className="Retair-submenu-container"
            >
              {
                (submenuContent.contentType=='simple')
                ?
                  <React.Fragment>
                    {
                      (submenuContent.items.length==2)?
                        <div style={{width:'10em'}} className="Retair-submenu-container"></div>
                      : <React.Fragment/>
                    }
                    {
                      submenuContent.items.map(item => {
                        return (
                          <Link className="Retair-submenu-container" href={item.link} style={{color:'white', marginTop:'8.5em', marginBottom:'2em', marginLeft:'3em', marginRight:'3em'}}>
                            {item.name}
                          </Link>
                        )
                      })
                    }
                    {
                      (submenuContent.items.length==2)?
                        <div className="Retair-submenu-container" style={{width:'10em'}}></div>
                      : <React.Fragment/>
                    }
                  </React.Fragment>
                :
                  <React.Fragment>
                    {
                      submenuContent.items.map(group => {
                        return (
                          <Box className="Retair-submenu-container" boxShadow={3} m={1} p={1} style={{padding:'0',marginTop:'8.5em', marginBottom:'2em', backgroundColor:grey[700]}}>
                            <div 
                              className="Retair-submenu-container"
                              style={{width:'100%', paddingLeft:'1em', paddingRight:'1em', backgroundColor:grey[800], 
                                borderLeft: '0.2em solid '+grey[700], 
                                borderRight: '0.2em solid '+grey[700], 
                                borderTop: '0.2em solid '+grey[700],
                                paddingTop:'0.6em', paddingBottom:'0.6em'
                              }}
                            >
                              <Typography className="Retair-submenu-container" style={{fontSize:'1em'}}>
                                {group.groupName}
                              </Typography>
                            </div>
                            {
                              group.items.map(item => {
                                return (
                                  <Link className="Retair-submenu-container" href={item.link} style={{display:'block',color:'white', marginTop:'1em', marginBottom:'0.5em', marginLeft:'3em', width:'100%'}}>
                                    {item.name}
                                  </Link>
                                )
                              })
                            }
                          </Box>
                        )
                      })
                    }
                  </React.Fragment>
              }
            </Container>
          </AppBar>
        :<React.Fragment/>
      }
    </React.Fragment>
  );
}



const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    marginTop: '1em',
    marginBottom: '1em'
  },
}))




function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {
          (props.iconType=='material-ui-icon')?
            <LabelIcon color="inherit" className={classes.labelIcon} />
          :
            <img src={props.labelIcon} alt={"label-icon"} style={{width:'24px', height:'24px', marginRight:'8px'}}/>
          }
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};





function HeaderMobile(props) {
  const history = useHistory()
  const theme = useTheme()

  const [open, setOpen] = React.useState(false)

  const signInButtonClick = () => {
    history.push('/apps')
  }

  const drawerWidth = 240

  const useStyles = makeStyles((theme) => ({

    logo: {
      width: theme.spacing(18),
      height: theme.spacing(9),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      cursor: 'pointer'
    },
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    root1: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
    },
  }));

  const classes = useStyles()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
         style={{backgroundColor:grey[900]}}
      >
        <Toolbar>
          <img src={logoSlogan} className={classes.logo} alt={"Логотип Retair"} onClick={e=>history.push('/')}/>
          <h1 style={{color:'white', marginLeft:'2em'}}>{props.title}</h1>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
            style={{marginLeft:'auto'}}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <TreeView
          className={classes.root1}
          defaultExpanded={['3']}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          {
            menuItems.map((menuItem,menuItemIndex) => {
              return (
                <StyledTreeItem nodeId={menuItemIndex+''} labelText={menuItem.name} labelIcon={menuItem.icon} iconType={menuItem.iconType}>
                  {
                    (menuItem.contentType=='simple')?
                      <React.Fragment>
                        {
                          menuItem.items.map((subItem,subItemIndex) => {
                            return (
                              <StyledTreeItem nodeId={(subItemIndex+100*menuItemIndex)+''} labelText={subItem.name} labelIcon={subItem.icon}  iconType={subItem.iconType} 
                                onClick={e=>history.push('/'+subItem.link)}
                              />
                            )
                          })
                        }
                      </React.Fragment>
                    :
                      <React.Fragment>
                        {
                          menuItem.items.map((group,groupIndex) => {
                            return (
                              <React.Fragment>
                                {
                                  group.items.map((subItem,subItemIndex)=>{
                                    return (
                                      <StyledTreeItem nodeId={(subItemIndex+(groupIndex+1)*1000)+''} labelText={subItem.name} labelIcon={subItem.icon}  iconType={subItem.iconType}
                                        onClick={e=>history.push('/'+subItem.link)}
                                      />
                                    )
                                  })
                                }
                              </React.Fragment>
                            )
                          })
                        }
                      </React.Fragment>
                  }
                </StyledTreeItem>
              )
            })
          }
        </TreeView>
      </Drawer>
    </React.Fragment>
  );
}


function Header(props) {

  const match1 = useMediaQuery('(max-width:950px)')
  
  return (
    <React.Fragment>
      {
        (match1)?
          <HeaderMobile title={props.title}/>
        :
          <HeaderDesktop/>
      }
    </React.Fragment>
  )
}

export default Header
