import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import Site from './components/site'
import AppProviders from './context'
import { BrowserRouter, Route } from 'react-router-dom'

import {themeX} from './styles/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { YMInitializer } from 'react-yandex-metrika'


ReactDOM.render(
	<AppProviders>
		<YMInitializer accounts={[68173015]} options={{webvisor: true}} version="2" />
		<ThemeProvider theme={themeX}>
			<BrowserRouter>
				<CssBaseline/>
				<Route exact path='/' component={Site}/>
				<Route path='/stock-management' component={Site}/>
				<Route path='/assortment' component={Site}/>
				<Route path='/stock-balancing' component={Site}/>
				<Route path='/nonseller-price-management' component={Site}/>
				<Route path='/ecommerce-site' component={Site}/>
				<Route path='/integration-1c' component={Site}/>
				<Route path='/contacts' component={Site}/>
				<Route path='/apps' component={App}/>
				<Route path='/colleagues' component={App}/>
				<Route path='/settings' component={App}/>
			</BrowserRouter>
		</ThemeProvider>
	</AppProviders>,
	document.getElementById('root'),
)

