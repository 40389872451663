import React from 'react'

const SiteContext = React.createContext()

function SiteProvider(props) {


  return( 
    <SiteContext.Provider 
      value={{
      }} {...props} 
    />
  )
}

export { SiteProvider, SiteContext };