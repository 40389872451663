import React, {useContext} from 'react'

import Divider from '@material-ui/core/Divider'

import withWidth from '@material-ui/core/withWidth'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Header from '../components/header'
import ChatWidget from '../components/chat-widget'
import PhoneCall from '../components/phone-call'
import Welcome from '../components/welcome'
import SuccessTrading from '../components/success-trading'
import Roadmap from '../components/roadmap'
import FastWinStrategy from '../components/fast-win-strategy'
import ExperienceContainer from '../components/experience'
import ChooseReason from '../components/choose-reason'
import Footer from '../components/footer'

import { SiteProvider } from './store.js'

function Site(props) {

  document.title = 'Retair - Цифровые решения для торговли'
  const matches = useMediaQuery('(max-width:600px)')
  
  return (
    <SiteProvider>
      <Header width={props.width}/>
      <ChatWidget width={props.width}/>
      <PhoneCall/>
      <main>
        <Welcome width={props.width}/>
        <SuccessTrading/>
        <Roadmap/>
        <FastWinStrategy width={props.width}/>
        <ExperienceContainer width={props.width}/>
        <Divider/>
        <ChooseReason width={props.width}/>
      </main>
      <Footer width={props.width}/>
    </SiteProvider>
  )
}

export default withWidth()(Site)
