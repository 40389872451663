import React, {useContext} from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import cloud from '../../images/cloud.png'
import star from '../../images/star.png'
import i247 from '../../images/i247.png'
import workout from '../../images/workout.png'
import notebook from '../../images/notebook.png'

import { makeStyles } from '@material-ui/core/styles'

function ChooseReason(props) {

  const useStyles = makeStyles((theme) => ({

    typography: {
      marginTop: theme.spacing(2),
    },
    chooseReasonCaption: {
      color: 'white',
      marginTop: theme.spacing(2),
    },
    cloud: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    workout: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    notebook: {
        width: (props.width=='xs')?theme.spacing(30):theme.spacing(50),
        height: (props.width=='xs')?theme.spacing(27):theme.spacing(45),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
  }))

  const classes = useStyles()
  
  return (
    <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '5.0vh', paddingTop: '5.0vh'}}>
      <Typography component="h5" variant="h5" align="center" className={classes.chooseReasonCaption}>
        Почему клиенты выбирают именно нас?
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={(props.width=='xs')?12:4}>
          <img src={cloud} className={classes.cloud} alt={"cloud"}/>
          <Typography component="h6" variant="h6" align="center" style={{color:'white'}}>
            Saas - решения
          </Typography>
          <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em', marginBottom: '2.0vh'}}>
            Сервис отраслевых решений под ключ
          </Typography>
        </Grid>
        <Grid item xs={(props.width=='xs')?12:4} style={{textAlign:'center'}}>
          <img src={i247} className={classes.cloud} alt={"i247"}/>
          <Typography component="h6" variant="h6" align="center" style={{color:'white'}}>
            24-часовая онлайн поддержка
          </Typography>
          <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em', marginBottom: '2.0vh'}}>
            Наши специалисты всегда на связи с вами по телефону / в мессенджерах / на почте
          </Typography>
        </Grid>
        <Grid item xs={(props.width=='xs')?12:4} style={{textAlign:'center'}}>
          <img src={star} className={classes.cloud} alt={"star"}/>
          <Typography component="h6" variant="h6" align="center" style={{color:'white'}}>
            Эксперты отрасли в команде RetAIR
          </Typography>
          <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em', marginBottom: '2.0vh'}}>
            Имея широкий опыт, мы нашли решение ваших бизнес-задач
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={(props.width=='xs')?12:4}>
          <img src={workout} className={classes.workout} alt={"workout"}/>
          <Typography component="h6" variant="h6" align="center" style={{color:'white'}}>
            Глубокая аналитика
          </Typography>
          <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em', marginBottom: '2.0vh'}}>
            Наш сервисы удобно управляются простой для пользователя бизнес-логикой, важной при принятии решений
          </Typography>
        </Grid>
        <Grid item xs={(props.width=='xs')?12:8} style={{textAlign:'center'}}>
          <img src={notebook} className={classes.notebook} alt={"notebook"}/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ChooseReason
