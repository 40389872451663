import React, {useContext} from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Carousel from 'react-material-ui-carousel'

import bolshieDetali from '../../images/bolshie-detali.png'
import rossko from '../../images/rossko.png'
import armtek from '../../images/armtek.png'
import truckmotors from '../../images/truckmotors.png'
import ok from '../../images/ok.png'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from "@material-ui/core/colors"

function Experience(props)
{
    var items = [
        // {
        //     image: armtek,
        //     logoWidth: 45,
        //     logoHeight: 7,
        //     serviceText: "Запущен в промышленную экплуатацию сервис Retair.АвтоЗаказ",
        //     kpiText: "В результате АБ-тестирование сервиса была достигнута положительная динамика KPI-компании",
        //     kpi: [
        //       {
        //         name: 'GMROI',
        //         value: '+15%'
        //       },
        //       {
        //         name: 'Оборачиваемость',
        //         value: '-35 дней'
        //       },
        //       {
        //         name: 'Наличие товара',
        //         value: '+15%'
        //       },
        //       {
        //         name: 'Рост продаж',
        //         value: '+15%'
        //       },
        //     ]
        // },
        {
            image: bolshieDetali,
            logoWidth: 45,
            logoHeight: 15,
            serviceText: "Успешно запущен в промышленную экплуатацию сервис Retair.АвтоЗаказ",
            kpiText: "В результате АБ-тестирование сервиса была достигнута положительная динамика KPI-компании",
            kpi: [
              {
                name: 'Оборачиваемость',
                value: '-15 дней'
              },
              {
                name: 'Наличие товара',
                value: '+2%'
              },
              {
                name: 'Сокращение штата',
                value: '-50%'
              },
            ]
        },
        {
            image: rossko,
            logoWidth: 45,
            logoHeight: 10,
            serviceText: "Сформирован ассортимент 40 магазинам с использованием сервиса Retair.Ассортимент",
            kpiText: "Учтенные факторы",
            kpiOnlyName: true,
            kpi: [
              {
                name: 'Локальный парк автомобилей и применимость деталей',
                value: ''
              },
              {
                name: 'История продаж в соседних торговых точках',
                value: ''
              },
              {
                name: 'Линии качества согласно покупательской способности локального рынка',
                value: ''
              },
            ]
        },
        {
            image: truckmotors,
            logoWidth: 45,
            logoHeight: 15,
            serviceText: "Запущен в промышленную экплуатацию сервис Retair.ЦеныНеликвидов",
            kpiText: "В результате 5-месячной эксплуатации объем неликвидов был сокращен на 70%",
            kpi: [
              {
                name: 'Товарный запас по неликвидам',
                value: '-70%'
              },
              {
                name: 'Рост продаж неликвидов',
                value: '+80%'
              },
              {
                name: 'Средняя маржинальная наценка распродаж',
                value: '-22%'
              },
            ]
        },
        {
            image: truckmotors,
            logoWidth: 45,
            logoHeight: 15,
            serviceText: "Запущен в промышленную экплуатацию сервис Retair.АвтоЗаказ",
            kpiText: "В результате АБ-тестирование сервиса была достигнута положительная динамика KPI-компании",
            kpi: [
              {
                name: 'GMROI',
                value: '+17%'
              },
              {
                name: 'Оборачиваемость',
                value: '-20 дней'
              },
              {
                name: 'Наличие товара',
                value: '+27%'
              },
              {
                name: 'Рост продаж',
                value: '+25%'
              },
            ]
        },
    ]
 
    return (
        <Carousel
          interval={9000}
        >
            {
                items.map( (item, i) => 
                  <ExperienceItem
                    key={i}
                    width={props.width}
                    image={item.image}
                    logoWidth={item.logoWidth}
                    logoHeight={item.logoHeight}
                    serviceText={item.serviceText}
                    kpiText={item.kpiText}
                    kpi={item.kpi}
                    kpiOnlyName={item.kpiOnlyName}
                  /> )
            }
        </Carousel>
    )
}
 
function ExperienceItem(props)
{
  const useStyles = makeStyles((theme) => ({
    companyLogo: {
        width: theme.spacing(props.logoWidth),
        height: theme.spacing(props.logoHeight),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    ok: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
  }))

  const classes = useStyles()

  return (
      <Paper>
          <Grid container spacing={3}>
            <Grid item xs={(props.width=='xs'||props.width=='sm')?12:6}>
              <Typography variant="h6" component="h1" style={{marginTop:'2em'}}>
                {props.serviceText}
              </Typography>
            </Grid>
            <Grid item xs={(props.width=='xs'||props.width=='sm')?12:6}>
              <img src={props.image} className={classes.companyLogo} alt={"companyLogo"}/>
            </Grid>

            {(props.width=='xs'||props.width=='sm')?<React.Fragment/>:<Grid item xs={1}/>}
            <Grid item xs={(props.width=='xs'||props.width=='sm')?12:4}>
              <Typography variant="h6" component="h1" align="center" style={{marginTop:'2em', fontSize:'1em'}}>
                {props.kpiText}
              </Typography>
            </Grid>
            {(props.width=='xs'||props.width=='sm')?<React.Fragment/>:<Grid item xs={1}/>}
            <Grid item xs={(props.width=='xs'||props.width=='sm')?12:6} style={{paddingLeft:'2em',paddingRight:'2em', marginBottom:'2em'}}>
              <Paper style={{backgroundColor:grey[700]}}>
                {
                  props.kpi.map(item=>{
                        return (
                          <Grid container spacing={3}>
                            <Grid item xs={2}>
                              <img src={ok} className={classes.ok} alt={"ok"}/>
                            </Grid>
                            <Grid item xs={(props.kpiOnlyName)?10:6}>
                              <Typography align="left">
                                {item.name}
                              </Typography>
                            </Grid>
                            {
                              (props.kpiOnlyName)?<React.Fragment/>:
                              <Grid item xs={4}>
                                <Typography align="left">
                                  {item.value}
                                </Typography>
                              </Grid>
                            }
                          </Grid>
                        )
                  })
                }
              </Paper>
            </Grid>

            
              
          </Grid>
      </Paper>
  )
}


function ExperienceContainer(props) {

  const useStyles = makeStyles((theme) => ({

    experienceCaption: {
      color: 'white',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }))

  const classes = useStyles()
  
  return (
    <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '5.0vh', paddingTop: '5.0vh'}}>
      <Typography component="h5" variant="h5" align="center" className={classes.experienceCaption}>
        Опыт наших клиентов
      </Typography>
      <Experience width={props.width}/>
    </Container>
  );
}

export default ExperienceContainer
