import React, {useContext} from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import inventoryManagement from '../../images/inventory-management.png'
import nonseller from '../../images/nonseller.png'
import replenishment from '../../images/replenishment.png'
import shop from '../../images/shop.jpg'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

import ProductCard from '../product-card'

function SuccessTrading(props) {

  const useStyles = makeStyles((theme) => ({

    typography: {
      marginTop: theme.spacing(2),
    },
    fastWinCaption: {
      color: 'white',
      marginTop: theme.spacing(2),
    },

  }))

  const classes = useStyles()

  const productsInfo = [
    {
      groupName: "Логистика и управление запасами",
      marginTop:'0',
      products: [
        {
          name:"Retair.АвтоЗаказ",
          image: inventoryManagement,
          description: "Сервис автоматически формирует задания на заказ поставщикам и заявки на локальные перемещения по системе корпоративных складов в режиме онлайн",
          marginRight: "1em", marginTop:"1em", marginLeft:"0", marginBottom:"0",
          link: "stock-management",
        },
        {
          name:"Retair.Балансировка",
          image: replenishment,
          description: "Сервис обеспечивает оперативное и автоматическое перераспределение товара по системе корпоративных складов по мере появления излишков, недостатков, дисбаланса линий качества и т.д. в режиме онлайн",
          marginRight: "1em", marginTop:"1em", marginLeft:"0", marginBottom:"0",
          link: "stock-balancing",
        }
      ]
    },
    {
      groupName: "Маркетинг и управление продажами",
      marginTop:'0',
      products: [
        {
          name:"Retair.ЦеныНеликвидов",
          image: nonseller,
          description: "Сервис выявляет максимально ликвидные цены, тем самым минимизируя финансовые потери при распродаже неликвидов",
          marginRight: "1em", marginTop:"1em", marginLeft:"0", marginBottom:"0",
          link: "nonseller-price-management",
        },
        {
          name:"Retair.Интернет-Магазин",
          image: shop,
          description: "Полнофункциональный интернет-магазин для товаров из наличия, товаров под заказ и с партнерской сети",
          marginRight: "1em", marginTop:"1em", marginLeft:"0", marginBottom:"0",
          link: "ecommerce-site",
        }
      ]
    }
  ]

  
  return (

        <div style={{backgroundColor:grey[900], width:'100%'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '5.0vh', paddingTop: '5.0vh'}}>
            <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption} style={{marginBottom: '2.0vh'}}>
              2 важных компонента успешной торговли:
            </Typography>

            <Grid container>
              {(props.width=='xs')?<React.Fragment/>:<Grid item xs={1}/>}
              <Grid item xs={(props.width=='xs')?12:10}>
                <Paper style={{backgroundColor:grey[900], width:'100%', padding:'1.0em', borderRadius:'5em 5em 5em 0'}}>
                  <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em', marginBottom: '2.0vh'}}>
                    Мы убедились, что 80% успеха торговой компании зависит от грамотного выстраивания нижеперечисленных процессов, и поэтому создали сервисы для оптимального управления
                  </Typography>
                </Paper>
              </Grid>
            </Grid>


            {
              productsInfo.map(group => {
                return (

                  <Accordion style={{marginTop:group.marginTop}} expanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography component="h6" variant="h6"  align="center" style={{width:"100%"}}>{group.groupName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={(group.products.length<=1)?{textAlign:'center'}:{display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:'center'}}>
                      {
                        group.products.map(product => {
                          return (

                            <ProductCard name={product.name} image={product.image} description={product.description}
                              marginRight={product.marginRight} marginTop={product.marginTop} marginLeft={product.marginLeft} marginBottom={product.marginBottom}
                              link={product.link}
                          />

                          )
                        })
                      }
                    </AccordionDetails>
                  </Accordion>

                )
              })
            }
          </Container>
        </div>
  );
}

export default SuccessTrading
