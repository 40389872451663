import React, {useContext} from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Textfield from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'

function LeftOrderDialog(props) {

	const { phoneNumber, setPhoneNumber, email, setEmail, service, setService,
			open, setOpen, handleSend, ...otherProps } = props

	const handleClose = () => {
	    setOpen(false)
	};

	const useStyles = makeStyles(theme => ({
	  formControl: {
	    margin: theme.spacing(1),
	    minWidth: 120,
	  },
	  selectEmpty: {
	    marginTop: theme.spacing(2),
	  },
	}))

	const classes = useStyles()

	return (
		<Dialog
		    open={open}
		    onClose={handleClose}
			>
			<DialogContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				{
					(props.chooseService)?
						<React.Fragment>
							<DialogContentText>
								Какой сервис Вас больше всего заинтересовал?
							</DialogContentText>
							<FormControl variant="outlined" style={{width:'16em'}}>
						        <InputLabel>Выберите сервис...</InputLabel>
						        <Select
						          value={service}
						          onChange={e=>setService(e.target.value)}
						        >
						          <MenuItem value="Retair.АвтоЗаказ">Retair.АвтоЗаказ</MenuItem>
						          <MenuItem value="Retair.ЦеныНеликвидов">Retair.ЦеныНеликвидов</MenuItem>
						          <MenuItem value="Retair.Интернет-магазин">Retair.Интернет-магазин</MenuItem>
						          <MenuItem value="Retair.Балансировка">Retair.Балансировка</MenuItem>
						        </Select>
						    </FormControl>
						</React.Fragment>
					:
						<React.Fragment/>
				}
				<DialogContentText style={{marginTop: '2em',}}>
					Оставьте свои контакты, наш бизнес-эксперт перезвонит Вам и расскажет о всех деталях сервиса
				</DialogContentText>
		        <Textfield
		          label="Электронная почта"
		          variant="outlined"
		          style={{marginTop: '1em',}}
		          value={email}
		          onChange={(e) => setEmail(e.target.value)}
		        />
		        <Textfield
		          label="Телефон"
		          variant="outlined"
		          style={{marginTop: '1em',}}
		          value={phoneNumber}
		          onChange={(e) => setPhoneNumber(e.target.value)}
		        />
				<Button onClick={handleSend} color="primary" variant="contained"
					style={{marginTop: '1em', marginBottom:'1vh'}}
				>
					Отправить заявку
				</Button>
			</DialogContent>
		</Dialog>
	);
}

export default LeftOrderDialog
