import React from 'react'

import withWidth from '@material-ui/core/withWidth'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Header from '../components/header'
import ChatWidget from '../components/chat-widget'
import Footer from '../components/footer'

function Contacts(props) {

  document.title = 'Контакты'

  return (
    <React.Fragment>
      <Header width={props.width}/>
      <ChatWidget width={props.width}/>
      <main style={{marginTop:'6em'}}>
      </main>
      <Footer width={props.width}/>
    </React.Fragment>
  )
}

export default withWidth()(Contacts)
