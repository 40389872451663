import React from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Textfield from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Arrow from '@elsdoerfer/react-arrow'

import withWidth from '@material-ui/core/withWidth'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Header from '../components/header'
import ChatWidget from '../components/chat-widget'
import PhoneCall from '../components/phone-call'
import Footer from '../components/footer'

import GridOnIcon from '@material-ui/icons/GridOn'
import SettingsIcon from '@material-ui/icons/Settings'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

import logo1C from '../images/logo-1c.png'
import logoRetairVertical from '../images/retair_vertical.png'

import LeftOrderDialog from '../components/left-order'
import MessageDialog from '../components/message-dialog'
import {getChatToken, setChatToken} from '../components/chat-widget/token.js'


function TableCard(props) {

  const useStyles = makeStyles((theme) => ({
    cardText: {
      color: 'white',
      marginTop: theme.spacing(2),
      fontSize:'0.85em'
    }


  }))

  const classes = useStyles()

  return (
    <Grid item xs={(props.width=='xs')?12:6} style={{padding:'0.5em 0.5em 1em 0.5em'}}>
      <Card>
        <CardContent>
          <GridOnIcon/>
          <Typography component="h6" variant="h6" align="center" className={classes.cardText}>
            {props.text}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}




function Integration1C(props) {

  document.title = 'Интеграция с 1С'

  const useStyles = makeStyles((theme) => ({

    fastWinCaption: {
      color: 'white',
      marginTop: theme.spacing(2),
    },
    phylosophy: {
      color: 'white',
      marginTop: theme.spacing(2),
      fontSize:'1.2em'
    },
    keyConsideration: {
      color: 'white',
      marginTop: theme.spacing(2),
      fontSize:'1.5em'
    },
    h2: {
      color: 'white',
      marginTop: theme.spacing(2),
      fontSize:'1.75em'
    },
    h3: {
      color: 'white',
      marginTop: theme.spacing(2),
      fontSize:'1.5em'
    },


  }))

  const classes = useStyles()

  const match1 = useMediaQuery('(max-width:950px)')

  const [openDialog, setOpenDialog] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [service, setService] = React.useState('')
  const [openMessageDialog,setOpenMessageDialog] = React.useState(false)

  const handleSend = () => {
    setOpenDialog(false)
    fetch('/send-message/', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({token: getChatToken(),message: 'Очень интересно было бы пообщаться с одним из Ваших Заказчиков. Прошу предоставить контакты. Оставляю свои контакты для связи: электронная почта - '+email+', телефон - '+phoneNumber}),
        json: true,
    })
    setOpenMessageDialog(true)
  }

  return (
    <React.Fragment>
      <Header width={props.width} title=''/>
      <ChatWidget width={props.width}/>
      <PhoneCall/>
      <main style={{marginTop:(!match1)?'6em':'3em'}}>
      	<Container maxWidth="md" style={{textAlign:'center', paddingBottom: '5.0vh', paddingTop: (!match1)?'1.5vh':'4.0vh'}}>
          <h1 style={{color:'white'}}>Интеграция с 1С</h1>
          <Typography align="justify" className={classes.phylosophy} style={{marginTop:'0'}}>
            Структура платформы Retair и взаимодействие с информационной системой 1С наших клиентов построены следующим образом: так как 80% рынка торговли основана на учетной системе 1С, мы все данные с 1С интегрируем посредством веб-сервиса для запуска различных приложений. “Под капотом” любой 1С имеется встроенный штатный OData веб-сервис, позволяющий с использованием специально разработанного нами компонента Retair.DataFlux получать любые данные без нагрузки на саму систему 1С  практически в режиме онлайн. Именно этим инструментом мы пользуемся для интеграции данных на Retair, чтобы запускать приложения. Такой подход мы заложили с самого начала создания платформы, не только мы, но и наши клиенты Вам открыто поделятся опытом, конкретной практикой. Интеграция с платформой проводится единожды, после чего Заказчику доступны разные приложения: Retair.Автозаказ, Retair.Интернет-Магазин, Retair.Балансировка и Retair.ЦеныНеликвидов.
          </Typography>
          <Typography component="h2" variant="h2" align="center" className={classes.h2}>
            Схема работы с платформой
          </Typography>
          <div
            style={{
              display:'flex',
              flexDirection: (!match1)?'row':'column',
              marginTop: '1em',
              marginBottom: '1em',
              alignItems: (!match1)?'stratch':'center'
            }}
          >


            <div
              style={{
                width: '300px',
                backgroundColor: grey[900],
                position: 'relative',
                textAlign: 'left'
              }}
            >
              <Typography component="h3" variant="h3" align="center" className={classes.h3}>
                Контур корпоративной системы 1С
              </Typography>
              <Typography align="left" style={{fontSize:'1em', margin:'1em'}}>
                В 1С <b>штатно</b> встроен API для взаимодействия с внешними сервисами
              </Typography>
              <img src={logo1C} alt={"Логотип 1С"}
                style={{
                  width: '150px',
                  height: '115px',
                  margin: '1em'
                }}
              />


              <div
                style={{
                  position:'absolute',
                  top: '12em',
                  left: '160px',
                  width: '120px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <div style={{height:'60px'}}>
                  <Typography align="center" style={{fontSize:'1em', margin:'1em'}}>
                    Веб-сервис OData
                  </Typography>
                </div>
                <SettingsIcon style={{fontSize:'50px'}}/>
              </div>

            </div>


            <div
              style={{
                width: (!match1)?'100px':'10px',
                height: (!match1)?'100%':'70px',
                position: 'relative',
                marginTop: (!match1)?'17.5em':'1em'
              }}
            >
              <div style={{position:'absolute',width:'100%'}}>
                <Arrow
                  angle={(!match1)?90:0}
                  length={100}
                  color={'white'}
                  lineWidth={2}
                />
              </div>

              <div style={{position:'absolute',width:'100%'}}>
                <Arrow
                  angle={(!match1)?270:180}
                  length={100}
                  color={'white'}
                  lineWidth={2}
                />
              </div>
            </div>


            <div
              style={{
                width: (!match1)?'600px':'300px',
                backgroundColor: grey[900],
                position: 'relative'
              }}
            >
              <div
                style={{
                  display: (!match1)?'none':'flex',
                  width:'100%',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div
                  style={{
                    width: '85px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <div style={{height:'40px'}}>
                    <Typography align="center" style={{fontSize:'1em', margin:'1em'}}>
                      DataFlux
                    </Typography>
                  </div>
                  <SettingsIcon style={{fontSize:'50px'}}/>
                </div>
              </div>

              <Typography component="h3" variant="h3" align="center" className={classes.h3}>
                Контур платформы RETAIR
              </Typography>


              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  width:'100%',
                  marginTop:'1em'
                }}
              >
                <div style={{width: (!match1)?'275px':'145px'}}/>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    width: '200px'
                  }}
                >
                  <Typography align="center">
                    Приложения
                  </Typography>
                </div>
              </div>


              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  alignItems: 'center'
                }}
              >
                <div style={{width: (!match1)?'130px':'0px'}}/>
                <img src={logoRetairVertical} alt={"Логотип Retair"}
                  style={{
                    width: (!match1)?'85px':'60px',
                    height: (!match1)?'115px':'100px',
                    margin: '1em'
                  }}
                />
                <div
                  style={{
                    height: '350px',
                    width:'30px',
                    backgroundColor: grey[800],
                    WebkitClipPath: 'polygon(100% 0%,  100% 100%, 100% 100%, 0% calc(100% - 175px), 0% 175px)',
                    clipPath: 'polygon(100% 0%,  100% 100%, 100% 100%, 0% calc(100% - 175px), 0% 175px)'
                  }}
                />
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    marginLeft: '1em',
                    width: (!match1)?'200px':'160px'
                  }}
                >
                  <div style={{backgroundColor:grey[800], padding:'0.5em 1em 0.5em 1em'}}>
                    <Typography align="left" style={{marginTop:'0',fontSize:(!match1)?'1.2em':'1.0em'}}>
                      Автозаказ
                    </Typography>
                  </div>
                  <div style={{backgroundColor:grey[800], padding:'0.5em 1em 0.5em 1em', marginTop: '2em'}}>
                    <Typography align="left" style={{marginTop:'0',fontSize:(!match1)?'1.2em':'1.0em'}}>
                      Интернет-магазин
                    </Typography>
                  </div>
                  <div style={{backgroundColor:grey[800], padding:'0.5em 1em 0.5em 1em', marginTop: '2em'}}>
                    <Typography align="left" style={{marginTop:'0',fontSize:(!match1)?'1.2em':'1.0em'}}>
                      Управление ценами неликвидов
                    </Typography>
                  </div>
                  <div style={{backgroundColor:grey[800], padding:'0.5em 1em 0.5em 1em', marginTop: '2em'}}>
                    <Typography align="left" style={{marginTop:'0',fontSize:(!match1)?'1.2em':'1.0em'}}>
                      Балансировка
                    </Typography>
                  </div>
                </div>
              </div>

              <div
                style={{
                  position:'absolute',
                  top: '12em',
                  left: '0px',
                  width: '85px',
                  display: (!match1)?'flex':'none',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <div style={{height:'60px'}}>
                  <Typography align="center" style={{fontSize:'1em', margin:'1em'}}>
                    DataFlux
                  </Typography>
                </div>
                <SettingsIcon style={{fontSize:'50px'}}/>
              </div>
            </div>


          </div>


          <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', marginBottom:'3vh'}}>
            <div style={{width:'600px', backgroundColor:grey[800], padding:'1em'}}>
              <Typography align="center" style={{fontSize:'1.5em', marginBottom:'0.5em'}}>
                Использование эффективных инструментов - стезя профессионалов и инноваторов, не просто для того, чтобы быть лучшим, но в первую очередь усилить экономику Компании. Если Вы хотите узнать опыт прямо из уст наших Заказчиков, жмите на кнопку “Получить контакты”, с целью помочь Вам решить бизнес-задачи мы с удовольствием поделимся контактами клиентов, которые уже пользуются технологиями Retair.
              </Typography>
              <Button variant="contained" color="primary"
                onClick={e=>setOpenDialog(true)}
              >
                Получить контакты
              </Button>
            </div>
          </div>

          <LeftOrderDialog
            phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
            email={email} setEmail={setEmail}
            service={service} setService={setService}
            open={openDialog} setOpen={setOpenDialog} 
            handleSend={handleSend}
            chooseService={false}
          />
          <MessageDialog open={openMessageDialog} setOpen={setOpenMessageDialog} message="Благодарим за оставленную заявку. Сотрудник Retair свяжется с Вами в ближайшее время."/>


          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography component="h6" variant="h6" align="justify" className={classes.phylosophy} style={{marginTop:'0'}}>
                Мы придерживаемся философии, что центр управления всей информацией должен быть единым, только этот вариант позволяет качественно управлять компанией, показателями  и ключевыми бизнес-процессами.
              </Typography>
              <Typography component="h6" variant="h6" align="justify" className={classes.phylosophy}>
                Когда Вы покупаете подписку на приложение, ничего не поменяется в организационной жизни компании, просто у Вас появится современный и эффективный продукт в веб-пространстве.  Все построено на тех же правилах, которые у Вас заложены в 1С, Вам самим с точки зрения ИТ ничего дорабатывать не нужно, приложения заработают без дополнительных действий. Справа перечень популярных параметров, которые приложение сразу подхватит и начнет функционировать. Пришел новый товар, через секунду он стал доступным всем пользователям в интернет-магазине, либо же пересчитался Автозаказ, поменяли цену - аналогично, и так по всей информации и снова никаких дополнительных действий.
              </Typography>
              <Typography component="h6" variant="h6" align="justify" className={classes.phylosophy}>
                <b>Резюме:</b> центр входа информации - это 1С, дальше она транслируется на соответсвующие приложения в платформе.
              </Typography>
              <Typography component="h6" variant="h6" align="justify" className={classes.keyConsideration}>
                Мы против дополнительных админок.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <TableCard text="Остатки на складах" width={props.width}/>
                <TableCard text="Товары в пути" width={props.width}/>
                <TableCard text="История продаж" width={props.width}/>
                <TableCard text="Справочник номенклатуры" width={props.width}/>
                <TableCard text="Сбытовые цены" width={props.width}/>
                <TableCard text="Кроссы" width={props.width}/>
                <TableCard text="Партнеры" width={props.width}/>
                <TableCard text="Контрагенты" width={props.width}/>
              </Grid>
            </Grid>
          </Grid>
          <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
	      </div>
        </Container>
      </main>
      <Footer width={props.width}/>
    </React.Fragment>
  )
}

export default withWidth()(Integration1C)
