import React, {useContext} from 'react'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'

import roadmap01 from '../../images/roadmap01.png'
import roadmap02 from '../../images/roadmap02.png'
import roadmap03 from '../../images/roadmap03.png'
import roadmap04 from '../../images/roadmap04.png'
import roadmap05 from '../../images/roadmap05.png'
import roadmap06 from '../../images/roadmap06.png'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from "@material-ui/core/colors"

function RoadmapItem(props) {

  
  const useStyles = makeStyles((theme) => ({
    roadmapIcon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        margin: 'auto'
    },
    roadmapElement: {
        width: theme.spacing(18),
        marginBottom: theme.spacing(5),
        border: '1px solid '+grey[800],
        backgroundColor: grey[800],
        borderRadius: '0',
        padding: '0.3em'
    },
    roadmapNumber: {
        fontSize: '1.2em',
    },
    roadmapHeader: {
        fontSize: '1.2em',
    },
    roadmapDescription: {
        fontSize: '0.75em',
    },
    roadmapIcon: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
  }))

  const classes = useStyles()
  
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body2" color="textSecondary">
          {props.time}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        {
        (props.image)?
          <TimelineDot>
            <img src={props.image} className={classes.roadmapIcon} alt={"roadmapIcon"}/>
          </TimelineDot>
          :
          <TimelineDot />
        }
        {(props.finish)?<React.Fragment/>:<TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {
          (props.header.length>0)?
            <Paper elevation={3} style={{padding:'0.5em'}}>
              <Typography variant="h6" component="h1">
                {props.header}
              </Typography>
              {(props.description.length>0)?<Typography style={{fontSize:'0.8em'}}>{props.description}</Typography>:<React.Fragment/>}
            </Paper>
          :<React.Fragment/>
        }
      </TimelineContent>
    </TimelineItem>
  )
}



function Roadmap(props) {

  const useStyles = makeStyles((theme) => ({

    roadmapCaption: {
      color: 'white',
      marginTop: theme.spacing(2),
    },

  }))

  const classes = useStyles()
  
  return (
        <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '3.0vh', paddingTop: '3.0vh'}}>
          <Typography component="h5" variant="h5" align="center" className={classes.roadmapCaption} style={{marginBottom: '4.0vh'}}>
            Дорожная карта подключения к нашим сервисам
          </Typography>

          <Timeline align="alternate">
            <RoadmapItem number="1" time="Старт проекта" header="Интеграция с платформой Retair" description="Проводится единожды, после чего можно подключить любое приложение платформы" image={roadmap01}/>
            <RoadmapItem number="2" time="3-я неделя" header="Адаптация алгоритма под цели" description="Сбор требований и конфигурация настроек алгоритмов под цели Заказчика" image={roadmap02}/>
            <RoadmapItem number="3" time="5-я неделя" header="Метрики и их исполнение" description="АБ-тестирование и опытно-промышленная эксплуатация сервиса на ограниченном наборе объектов" image={roadmap03}/>
            <RoadmapItem number="4" time="13-я неделя" header="Визуализация информации" description="Настройка дэшбордов по требованиям Заказчика" image={roadmap04}/>
            <RoadmapItem number="5" time="15-я неделя" header="Управление ключевыми параметрами" description="Конфигурация тонких настроек алгоритмов через ключевые параметры" image={roadmap05}/>
            <RoadmapItem number="6" time="18-я неделя. Завершение проекта" header="" description=""  finish={true}/>
          </Timeline>

        </Container>
  );
}

export default Roadmap
