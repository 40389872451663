import React from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Textfield from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import withWidth from '@material-ui/core/withWidth'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Header from '../components/header'
import ChatWidget from '../components/chat-widget'
import PhoneCall from '../components/phone-call'
import Footer from '../components/footer'

import stockBalance from '../images/stock-balance.png'
import logo from '../images/retair_slogan.png'
import clients from '../images/clients.png'
import building from '../images/building.png'
import returnClient from '../images/return-client.png'
import problem from '../images/problem.png'

import { makeStyles,withStyles } from '@material-ui/core/styles'
import { grey, indigo, green, red, yellow,orange } from '@material-ui/core/colors'

import MessageDialog from '../components/message-dialog'
import LeftOrderDialog from '../components/left-order'
import {getChatToken, setChatToken} from '../components/chat-widget/token.js'

const GreyButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText('#39B9E5'),
    backgroundColor: '#39B9E5',
    '&:hover': {
      backgroundColor: '#59D9FF',
    },
  },
}))(Button)

function StockBalance(props) {

  document.title = 'Retair - Балансировка'

  const useStyles = makeStyles((theme) => ({

    logo: {
      opacity: '0.8',
      top: theme.spacing(10),
      cursor:'pointer',
      zIndex: '1',
      position: 'absolute'
    },
    fastWinCaption: {
      color: 'white',
      marginTop: theme.spacing(2),
    },
    forwho: {
      width: '20em',
      backgroundColor: grey[800],
      marginTop: '1vh',
      marginBottom: '3vh',
      padding: '1em',
      marginLeft: '1.5em'
    }


  }))

  const classes = useStyles()

  const [logoCoefficient, setLogoCoefficient] = React.useState(0.0)
  const match1 = useMediaQuery('(max-width:950px)')
  const match2 = useMediaQuery('(max-width:1120px)')
  const match3 = useMediaQuery('(max-width:1200px)')
  const match4 = useMediaQuery('(max-width:1300px)')

  const [openDialog, setOpenDialog] = React.useState(false)
  const [openMessageDialog,setOpenMessageDialog] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [service, setService] = React.useState('')
  const [message, setMessage] = React.useState('')

  React.useEffect(()=>{
    if(!match4) setLogoCoefficient(1.0)
    else if(!match3) setLogoCoefficient(0.9)
    else if(!match2) setLogoCoefficient(0.8)
    else if(!match1) setLogoCoefficient(0.6)
    else setLogoCoefficient(0)
  },[match1,match2,match3])

  const handleSend = () => {
    setOpenDialog(false)
    fetch('/send-message/', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({token: getChatToken(),message: message+' Мои контакты: электронная почта - '+email+', телефон - '+phoneNumber}),
        json: true,
    })
    setOpenMessageDialog(true)
  }

  return (
    <React.Fragment>
      <Header width={props.width} title=''/>
      <ChatWidget width={props.width}/>
      <PhoneCall/>
      <main style={{marginTop:'6em'}}>


        <div className={classes.heroContent} style={{backgroundImage: 'url(' + stockBalance + ')', backgroundPosition:'center'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '8.0vh', paddingTop: (!match1)?'1.5vh':'4.0vh'}}>
            <Typography component="h1" align="center" color="textPrimary" gutterBottom style={{color:'white',marginTop:'3vh',fontSize:(match1)?'1.9em':'2.5em'}}>
              Retair.Балансировка
            </Typography>
            <Grid container>
              <Grid item xs={(match1)?4:6}/>
              <Grid item xs={(match1)?8:6} style={{marginTop:'3vh'}}>
                <Typography component="h1" variant="h5" align="left" color="textPrimary" gutterBottom style={{color:'white'}}>
                  Автоматизированное перераспределение запасов
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={(match1)?4:6}/>
              <Grid item xs={(match1)?8:6}>
                <Typography variant="h6" align="left" color="textSecondary" paragraph style={{color:'white',fontSize:'1em'}}>
                  Все товары будут находиться именно там, где их хотят купить Ваши клиенты
                </Typography>
              </Grid>
              <Grid item xs={6}/>
              <Grid item xs={6}>
                <div>
                  <Grid container spacing={2} justify="left">
                    <Grid item>
                      <Button variant="contained" color="primary"
                        onClick={e=>{
                          setMessage('Оставил заявку на рассказ бизнес-эксперта о приложении Балансировка.')
                          setOpenDialog(true)
                        }}
                      >
                        Оставить заявку
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <img src={logo} className={classes.logo} alt={"Логотип Retair"}
          style={{
            width: logoCoefficient*22 + 'em',
            height: logoCoefficient*11 + 'em',
            marginBottom: 1 + 'em',
            marginTop: 4 + 'em',
            marginLeft: logoCoefficient*8 + 'em',
          }}
        />




        <div style={{backgroundColor:grey[900], width:'100%'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '5.0vh', paddingTop: '5.0vh'}}>
            <Typography align="left" style={{fontSize:'1.2em',marginBottom: '2.0vh'}}>
              Каждая вторая компания в России имеет дисбаланс товаров в торговой сети и как следствие потерю 15% прибыли в год.
            </Typography>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                flexWrap:'wrap'
              }}
            >
              <div style={{width:'500px', paddingRight:'1em'}}>
                <Typography align="left" style={{fontSize:'1.2em',marginBottom: '2.0vh'}}>
                  Неудовлетворенный покупатель уходит к конкуренту, не найдя товара у Вас - и это может стать тенденцией потребительского поведения и сигналом о начале спада продаж.
                </Typography>
              </div>
              <img src={clients} alt={"Клиенты"}
                style={{
                  width: '160px',
                  height: '80px',
                  marginLeft: '1em',
                  marginTop: '30px'
                }}
              />
              <div style={{position:'relative',marginLeft: '1em'}}>
                <div style={{width:'125px'}}>
                  <Typography align="center" style={{fontSize:'1em',marginBottom: '2.0vh'}}>
                    Ваш конкурент
                  </Typography>
                </div>
                <img src={building} alt={"Конкурент"}
                  style={{
                    width: '125px',
                    height: '125px',
                    position: 'absolute',
                    top: '0px',
                    left: '0px'
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display:'flex',
                flexDirection:'row',
                marginTop:(match1)?'4em':'0',
                alignItems:'center'
              }}
            >
              <img src={returnClient} alt={"Клиент вернись"}
                style={{
                  width: '200px',
                  height: '100px',
                  marginRight: '1em'
                }}
              />
              <div style={{width:'700px', paddingLeft:'1em'}}>
                <Typography align="left" style={{fontSize:'1.2em',marginBottom: '2.0vh'}}>
                  Пора остановить отток ваших клиентов - самый простой способ это <a href='/contacts' style={{color:'white'}}>регулярное проведение балансировки товаров.</a>
                </Typography>
              </div>
            </div>
          </Container>

          <Container maxWidth="md" style={{backgroundColor:'#552B2B',textAlign:'center', paddingBottom: '1.0vh', paddingTop: '2.0vh'}}>
            <Typography align="left" style={{fontSize:'1.2em',marginBottom: '1.0vh'}}>
              Для этого Вам не потребуется:
            </Typography>
            <ul>
              <li>
                <Typography align="left" style={{fontSize:'1.2em',marginBottom: '0.5vh'}}>
                  придумать методику перераспределения товара;
                </Typography>
              </li>
              <li>
                <Typography align="left" style={{fontSize:'1.2em',marginBottom: '0.5vh'}}>
                  найм дополнительного персонала;
                </Typography>
              </li>
              <li>
                <Typography align="left" style={{fontSize:'1.2em',marginBottom: '0.5vh'}}>
                  сверх нагружать текущих сотрудников новыми задачами;
                </Typography>
              </li>
              <li>
                <Typography align="left" style={{fontSize:'1.2em',marginBottom: '0.5vh'}}>
                  ежедневного ручного расчета избыточных и недостаточных запасов точек торговой сети;
                </Typography>
              </li>
              <li>
                <Typography align="left" style={{fontSize:'1.2em',marginBottom: '0.5vh'}}>
                  рисковать и допускать вероятность ошибок расчетов, связанных с человеческим фактором;
                </Typography>
              </li>
              <li>
                <Typography align="left" style={{fontSize:'1.2em',marginBottom: '0.5vh'}}>
                  и многое другое.
                </Typography>
              </li>
            </ul>
          </Container>

          <Container maxWidth="md" style={{backgroundColor:'#2B552B',textAlign:'center', paddingBottom: '1.0vh', paddingTop: '2.0vh'}}>
            <Typography align="left" style={{fontSize:'1.2em',marginBottom: '1.0vh'}}>
              Чтобы остановить отток клиентов к конкурентам и настроить балансировку товаров в Вашей Компании нужно:
            </Typography>
            <ul>
              <li>
                <Typography align="left" style={{fontSize:'1.2em',marginBottom: '0.5vh'}}>
                  связаться с бизнес-экспертом Retair и подключить приложение автоматической балансировки товаров Retair.Балансировка.
                </Typography>
              </li>
            </ul>
            <Button variant="contained" color="primary"
              onClick={e=>{
                setMessage('Хочу узнать о персональной скидке у бизнес-эксперта для приложения Retair.Балансировка')
                setOpenDialog(true)
              }}
            >
              Узнать о персональной скидке у бизнес-эксперта Retair
            </Button>
          </Container>

          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '4.0vh', paddingTop: '1.0vh'}}>
          </Container>
        </div>

        

        <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '3.0vh', paddingTop: '3.0vh'}}>
          <Typography component="h5" variant="h5" align="center" style={{fontSize:'2.0em',marginBottom: '2.5vh'}}>
            Что такое дисбаланс и балансировка товарных запасов?
          </Typography>
          <Typography align="left" style={{fontSize:'1.2em', marginBottom:'0.5em'}}>
            <b style={{color:'#69E9FF'}}><u>Дисбаланс товара</u></b> - нахождение конкретного артикула в избыточном количестве в одной из точек продаж торговой сети, будучи в недостатке в другой точке продаж.
          </Typography>
          <Typography align="left" style={{fontSize:'1.2em', marginBottom:'0.5em'}}>
            Простыми словами, дисбалансом товара можно назвать тот момент, когда клиент не находит нужный продукт в конкретной точке продаж, хотя артикул присутствует в Вашей торговой сети.
          </Typography>
          <Typography align="left" style={{fontSize:'1.2em', marginBottom:'2.5em'}}>
            В результате Вы имеете иррациональный итог и теряете явную прибыль при существующем спросе: нужным товаром Вы торгуете, но целевой потребитель не может его купить в одной из точек продаж.
          </Typography>
          <Typography align="left" style={{fontSize:'1.2em', marginBottom:'0.5em'}}>
            Для исправления ситуации необходимо провести балансировку товаров в торговой сети, то есть устранить дисбаланс.
          </Typography>
          <Typography align="left" style={{fontSize:'1.2em', marginBottom:'2.5em'}}>
            <b style={{color:'#69E9FF'}}><u>Балансировка</u></b> - это расчет достаточного количества запаса во всех торговых точках, выявление избыточных, недостаточных запасов и последующее перемещение товаров с “избыточных” точек в “недостаточные” точки продаж Вашего бизнеса.
          </Typography>
        </Container>



        <div className={classes.heroContent} style={{backgroundImage: 'url(' + problem + ')', backgroundPosition:'center'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '8.0vh', paddingTop: (!match1)?'1.5vh':'4.0vh'}}>
            <Typography component="h1" align="center" color="textPrimary" gutterBottom style={{color:'white',marginTop:'3vh',fontSize:(match1)?'1.9em':'2.5em'}}>
              Насколько масштабна проблема дисбаланса товарного запаса именно в Вашей компании?
            </Typography>
            <Grid container>
              <Grid item xs={(match1)?4:5}/>
              <Grid item xs={(match1)?8:7} style={{marginTop:'3vh'}}>
                <Typography component="h1" variant="h5" align="left" color="textPrimary" gutterBottom style={{color:'white'}}>
                  Помогут определить бизнес-эксперты Retair
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={(match1)?4:5}/>
              <Grid item xs={(match1)?8:7}>
                <Typography variant="h6" align="left" color="textSecondary" paragraph style={{color:'white',fontSize:'1em'}}>
                  По методике приведенной ниже
                </Typography>
              </Grid>
              <Grid item xs={5}/>
              <Grid item xs={7}>
                <div>
                  <Grid container spacing={2} justify="left">
                    <Grid item>
                      <Button variant="contained" color="primary"
                        onClick={e=>{
                          setMessage('Помогите определить масштаб проблемы с дисбалансом товара')
                          setOpenDialog(true)
                        }}
                      >
                        Оценить масштаб дисбаланса в Вашей компании
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>


        <div style={{backgroundColor:grey[900], width:'100%'}}>
          <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '5.0vh', paddingTop: '5.0vh'}}>
            <Typography component="h5" variant="h5" align="center" style={{fontSize:'2.0em',marginBottom: '2.5vh'}}>
              Методика оценки индекса дисбаланса товарных запасов
            </Typography>
            <Typography align="left" style={{fontSize:'1.2em',marginBottom: '2.0vh'}}>
              Масштаб проблемы дисбаланса товарных запасов нужно оценивать с помощью <b style={{color:'#69E9FF'}}>индекса дисбаланса.</b> Простыми словами, индекс дисбаланса - это доля товаров, находящихся в дисбалансе по отношению ко всему перечню артикулов рабочего ассортимента торговой сети.
            </Typography>
            <Typography align="left" style={{fontSize:'1.2em',marginBottom: '2.0vh'}}>
              Мы подскажем, как с помощью 6 простых шагов Вы сможете это сделать даже самостоятельно.
            </Typography>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="h6" variant="h6"  align="left" style={{width:"100%"}}>1. Подготовка данных.</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display:'block'}}>
                <div
                  style={{
                    width:'100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div style={{width:'600px', marginRight:'3em'}}>
                    <Typography  align="left" style={{fontSize:'1em'}}>
                      1.1. Составляется перечень артикулов, которые находятся в рабочем ассортименте хотя бы одной из точек торговой сети.
                    </Typography>
                  </div>
                  <TableContainer style={{width:'200px'}}>
                    <Table>
                      <TableHead>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Артикул</b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              АРТ-00001
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              АРТ-00002
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              АРТ-00003
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              АРТ-04379
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>



                <div
                  style={{
                    width:'100%',
                    marginTop: '3em'
                  }}
                >
                  <Typography  align="left" style={{fontSize:'1em'}}>
                    1.2. К каждому артикулу подтягиваются данные, перечисленные ниже, и группируются по торговым точкам:
                  </Typography>
                  <ul>
                    <li>
                      <Typography  align="left" style={{fontSize:'1em'}}>
                        РА (да/нет) – признак включения в рабочий ассортимент текущей торговой точки;
                      </Typography>
                    </li>
                    <li>
                      <Typography  align="left" style={{fontSize:'1em'}}>
                        Остатки – актуальные на данный момент остатки артикула в текущей торговой точке;
                      </Typography>
                    </li>
                    <li>
                      <Typography  align="left" style={{fontSize:'1em'}}>
                        Продажи 365 – сумма продаж в штуках артикула с текущей торговой точки за прошедшие 365 дней;
                      </Typography>
                    </li>
                    <li>
                      <Typography  align="left" style={{fontSize:'1em'}}>
                        СтраховЗапас – страховой запас в днях, подтянутый для текущего артикула (то есть на сколько дней продаж нужно держать товарного запаса на складе, для простоты можете заполнить это поле значением в 30 дней);
                      </Typography>
                    </li>
                    <li>
                      <Typography  align="left" style={{fontSize:'1em'}}>
                        Комплектность - самое популярное количество в чеках продажи данного артикула (либо средий чек).
                      </Typography>
                    </li>
                  </ul>

                  <TableContainer style={{width:'100%'}}>
                    <Table>
                      <TableHead>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}} rowSpan={2}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Артикул</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}} colSpan={5}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Торговая точка 1</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}} colSpan={5}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Торговая точка 2</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>...</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}} colSpan={5}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Торговая точка 15</b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>РА</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Остатки</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Продажи 365</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Страхов запас</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Комплектность</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>РА</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Остатки</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Продажи 365</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Страхов запас</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Комплектность</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>...</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>РА</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Остатки</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Продажи 365</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Страхов запас</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              <b>Комплектность</b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              АРТ-00001
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              да
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              3
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              7
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              нет
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              3
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              7
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              нет
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              АРТ-00002
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              нет
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              да
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              5
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              7
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              нет
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              АРТ-00003
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              да
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              4
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              18
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              14
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              да
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              14
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              7
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              да
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              3
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              АРТ-04379
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              да
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              2
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              7
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              7
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              да
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              6
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              7
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              нет
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              3
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              7
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="h6" variant="h6"  align="left" style={{width:"100%"}}>2. Расчет достаточного уровня запаса торговых точек.</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display:'block'}}>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'1em'}}>
                  Достаточный уровень запаса в штуках рассчитывается для каждой торговой точки в отдельном столбце по следующей последовательности действий:
                </Typography>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'0.25em'}}>
                  2.1. «Продажи 365» умножается на «СтраховЗапас» и делится на 365;
                </Typography>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'0.25em'}}>
                  2.2. Результат п.2.1 округляется вверх до целого значения и далее вверх до значения кратного показателю «Комплектность»;
                </Typography>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'1em'}}>
                  2.3. В случае если значение «РА» по торговой точке не выставлено, то достаточный уровень запаса приравнивается нулю.
                </Typography>

                <TableContainer style={{width:'100%'}}>
                  <Table>
                    <TableHead>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}} rowSpan={2}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            <b>Артикул</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}} colSpan={6}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Торговая точка 1</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}} colSpan={6}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Торговая точка 2</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>...</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}} colSpan={6}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Торговая точка 15</b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>РА</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Остатки</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Дост Остатки</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Продажи 365</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Страхов запас</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Комплектность</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>РА</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Остатки</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Дост Остатки</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Продажи 365</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Страхов запас</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Комплектность</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>...</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>РА</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Остатки</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Дост Остатки</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Продажи 365</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Страхов запас</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em 1.25em 0.25em 1.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            <b>Комплектность</b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            АРТ-00001
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            да
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            3
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            7
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            нет
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            3
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            7
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            нет
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            АРТ-00002
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            нет
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            да
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            5
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            7
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            нет
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            АРТ-00003
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            да
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            4
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            18
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            14
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            да
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            14
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            7
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            да
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            3
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            АРТ-04379
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            да
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            2
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            7
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            7
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            да
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            6
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            7
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#444455'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            нет
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[500], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            3
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            7
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '0px solid '+grey[700], lineHeight:'1em', padding:'0.25em', backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="h6" variant="h6"  align="left" style={{width:"100%"}}>3. Расчет недостаточных запасов торговых точек.</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display:'block'}}>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'1em'}}>
                  3.1. Недостаточный уровень запаса в штуках рассчитывается для каждой торговой точки по следующей последовательности действий:
                </Typography>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'0.25em', paddingLeft:'2em'}}>
                  3.1.1. Из значения показателя «Дост уровень запаса» вычитается значение показателя «Остатки»;
                </Typography>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'2em', paddingLeft:'2em'}}>
                  3.1.2. В случае если результат п.3.1.1 получился отрицательным, то он приравнивается к нулю.
                </Typography>
                <div
                  style={{
                    width:'100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div style={{width:'200px', marginRight:'3em'}}>
                    <Typography  align="left" style={{fontSize:'1em'}}>
                      3.2. Суммарные недостатки запасов всей торговой системы рассчитываются как сумма недостатков всех торговых точек в отдельности.
                    </Typography>
                  </div>
                  <TableContainer style={{width:'600px'}}>
                    <Table>
                      <TableHead>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Артикул</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Недост Торговая точка 1</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Недост Торговая точка 2</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Недост Торговая точка 15</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Недост всей торговой сети</b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              АРТ-00001
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              АРТ-00002
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              АРТ-00003
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              3
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              3
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              АРТ-04379
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              3
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              3
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="h6" variant="h6"  align="left" style={{width:"100%"}}>4. Расчет излишних запасов торговых точек.</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display:'block'}}>
              <Typography  align="left" style={{fontSize:'1em', marginBottom:'1em'}}>
                  4.1. Для каждой торговой точки кроме центрального склада рассчитывается излишний уровень запаса в штуках по следующей последовательности действий:
                </Typography>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'0.25em', paddingLeft:'2em'}}>
                  4.1.1. Из значения показателя «Остатки» вычитается значение показателя «Дост уровень запаса»;
                </Typography>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'2em', paddingLeft:'2em'}}>
                  4.1.2. В случае если результат п.4.1.1 получился отрицательным, то он приравнивается к нулю.
                </Typography>
                <div
                  style={{
                    width:'100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div style={{width:'200px', marginRight:'3em'}}>
                    <Typography  align="left" style={{fontSize:'1em'}}>
                      4.2. Суммарные излишние запасы всей торговой сети рассчитываются как сумма излишних запасов всех торговых точек в отдельности.
                    </Typography>
                  </div>
                  <TableContainer style={{width:'600px'}}>
                    <Table>
                      <TableHead>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Артикул</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Излш Торговая точка 2</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Излш Торговая точка 15</b>
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              <b>Излш всей торговой системы</b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="left" style={{fontSize:'1em'}}>
                              АРТ-00001
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              1
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              АРТ-00002
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              АРТ-00003
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{height:'1em'}}>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              АРТ-04379
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              31
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              ...
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              0
                            </Typography>
                          </TableCell>
                          <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                            <Typography  align="center" style={{fontSize:'1em'}}>
                              31
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="h6" variant="h6"  align="left" style={{width:"100%"}}>5. Расчет признака дисбаланса товаров.</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display:'block'}}>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'1em'}}>
                  Отдельным столбцом рассчитываем признак дисбаланса товарных запасов в торговой сети по следующей последовательности действий:
                </Typography>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'0.25em'}}>
                  5.1. Если значение показателей «Недостаточный уровень запасов всей торговой системы» и «Излишние запасы всей торговой системы» одновременно имеют положительное значение, то признаку дисбаланса присваиваем значение, равное единице;
                </Typography>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'2em'}}>
                  5.2. В противном случае признаку дисбаланса присваиваем значение, равное нулю.
                </Typography>
                <TableContainer style={{width:'600px'}}>
                  <Table>
                    <TableHead>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            <b>Артикул</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            <b>Недостатки всей торговой системы</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            <b>Излишки всей торговой системы</b>
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            <b>Дисбаланс</b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="left" style={{fontSize:'1em'}}>
                            АРТ-00001
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            АРТ-00002
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            3
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            АРТ-00003
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            1
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            ...
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{height:'1em'}}>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            АРТ-04379
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            3
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                        <TableCell style={{border: '1px solid '+grey[700], lineHeight:'1em', padding:'0.25em',backgroundColor:'#445544'}}>
                          <Typography  align="center" style={{fontSize:'1em'}}>
                            0
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="h6" variant="h6"  align="left" style={{width:"100%"}}>6. Определение индекса дисбаланса.</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display:'block'}}>
                <Typography  align="left" style={{fontSize:'1em', marginBottom:'1em'}}>
                  Индекс дисбаланса товарных запасов в торговой сети рассчитывается как отношение количества артикулов с признаком дисбаланса, равной единице, к общему количеству артикулов, находящихся в рабочем ассортименте хотя бы одной из торговых точек, переведенное в проценты.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Button variant="contained" color="primary" style={{marginTop:'2vh'}}
              onClick={e=>{
                setMessage('Хочу узнать как настроить автоматическую балансировку для моей компании')
                setOpenDialog(true)
              }}
            >
              Узнать как настроить автоматическую балансировку для Вашей компании
            </Button>

            <Typography align="left" style={{fontSize:'1.2em',marginBottom: '2.0vh', marginTop:'7vh'}}>
              Проверьте, насколько высок дисбаланс товарного запаса Вашего бизнеса: если этот показатель превышает значение в 5%, то для возобновления роста продаж Вашей компании свяжитесь с бизнес-экспертом Retair и узнайте о способах автоматической балансировки товаров конкретно Вашего бизнеса!
            </Typography>

            <Button variant="contained" color="primary" style={{marginTop:'2vh'}}
              onClick={e=>{
                setMessage('Хочу обсудить наиболее эффективные и адаптированные под мой бизнес инструменты автобалансировки товаров с экспертом Retair')
                setOpenDialog(true)
              }}
            >
              Обсудить наиболее эффективные и адаптированные под Ваш бизнес инструменты автобалансировки товаров с экспертом Retair
            </Button>
          </Container>
        </div>


      </main>
      <Footer width={props.width}/>
      <LeftOrderDialog
        phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
        email={email} setEmail={setEmail}
        service={service} setService={setService}
        open={openDialog} setOpen={setOpenDialog} 
        handleSend={handleSend}
        chooseService={false}
      />
      <MessageDialog open={openMessageDialog} setOpen={setOpenMessageDialog} message="Благодарим за оставленную заявку. Бизнес-эксперт свяжется с Вами в ближайшее время."/>
    </React.Fragment>
  )
}

export default withWidth()(StockBalance)
