import React, {useContext} from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import warehouse from '../../images/warehouse.png'
import logo from '../../images/retair_slogan.png'

import LeftOrderDialog from '../left-order'

import { makeStyles } from '@material-ui/core/styles'

import {getChatToken, setChatToken} from '../chat-widget/token.js'
import MessageDialog from '../message-dialog'

function Welcome(props) {

  const match1 = useMediaQuery('(max-width:950px)')
  const match2 = useMediaQuery('(max-width:1120px)')
  const match3 = useMediaQuery('(max-width:1200px)')
  const match4 = useMediaQuery('(max-width:1300px)')

  const [logoCoefficient, setLogoCoefficient] = React.useState(0.0)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [service, setService] = React.useState('')
  const [openMessageDialog,setOpenMessageDialog] = React.useState(false)

  React.useEffect(()=>{
    if(!match4) setLogoCoefficient(1.0)
    else if(!match3) setLogoCoefficient(0.9)
    else if(!match2) setLogoCoefficient(0.8)
    else if(!match1) setLogoCoefficient(0.6)
    else setLogoCoefficient(0)
  },[match1,match2,match3])

  const useStyles = makeStyles((theme) => ({

    logo: {
      opacity: '0.8',
      top: theme.spacing(10),
      cursor:'pointer',
      zIndex: '1',
      position: 'absolute'
    },
    heroContent: {
      padding: (props.width=='xs'||props.width=='sm')?theme.spacing(20, 0, 20):theme.spacing(20, 0, 30),
    },

  }))

  const classes = useStyles()

  React.useEffect(()=>{
    setChatToken()
  },[])

  const handleSend = () => {
    setOpenDialog(false)
    fetch('/send-message/', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({token: getChatToken(),message: 'Прошу рассказать подробнее о приложении '+service+'. Мои контакты: электронная почта - '+email+', телефон - '+phoneNumber}),
        json: true,
    })
    setOpenMessageDialog(true)
  }
  
  return (
    <React.Fragment>
      <div className={classes.heroContent} style={{backgroundImage: 'url(' + warehouse + ')', backgroundPosition:'center'}}>
        <Container maxWidth="sm" style={{zIndex: '2'}}>
          <Grid container>
            <Grid item xs={(props.width=='xs'||props.width=='sm')?0:1}/>
            <Grid item xs={(props.width=='xs'||props.width=='sm')?12:11}>
              <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom style={{color:'white'}}>
                Технологичный IT-инструмент
              </Typography>
              <Typography variant="h6" align="center" color="textSecondary" paragraph style={{color:'white'}}>
                Подключайся к платформе и открой новые возможности цифровых решений для торговли вместе с Retair
              </Typography>
              <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                  <Grid item>
                    <Button variant="contained" color="primary"
                      onClick={e=>setOpenDialog(true)}
                    >
                      Оставить заявку
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <img src={logo} className={classes.logo} alt={"Логотип Retair"}
        style={{
          width: logoCoefficient*22 + 'em',
          height: logoCoefficient*11 + 'em',
          marginBottom: 1 + 'em',
          marginTop: 4 + 'em',
          marginLeft: logoCoefficient*8 + 'em',
        }}
      />
      <LeftOrderDialog
        phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
        email={email} setEmail={setEmail}
        service={service} setService={setService}
        open={openDialog} setOpen={setOpenDialog} 
        handleSend={handleSend}
        chooseService={true}
      />
      <MessageDialog open={openMessageDialog} setOpen={setOpenMessageDialog} message="Благодарим за оставленную заявку. Бизнес-эксперт свяжется с Вами в ближайшее время."/>
    </React.Fragment>
  )
}

export default Welcome
