import React, {useContext} from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import elephant from '../../images/elephant.png'
import rocket from '../../images/rocket.png'
import fastWinStrategyImg from '../../images/fast-win-strategy.png'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

function FastWinStrategy(props) {

  const useStyles = makeStyles((theme) => ({

    typography: {
      marginTop: theme.spacing(2),
    },
    fastWinCaption: {
      color: 'white',
      marginTop: theme.spacing(2),
    },
    elephant: {
        width: theme.spacing(30),
        height: theme.spacing(30),
        margin: 'auto'
    },
    rocket: {
        width: theme.spacing(30),
        height: theme.spacing(14),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(8),
    },
    fastWinStrategyImg: {
      width: (props.width=='xs'||props.width=='sm')?theme.spacing(45):theme.spacing(90),
      height: (props.width=='xs'||props.width=='sm')?theme.spacing(17):theme.spacing(35),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
  }))

  const classes = useStyles()
  
  return (
    <div style={{backgroundColor:grey[900], width:'100%'}}>
      <Container maxWidth="md" style={{textAlign:'center', paddingBottom: '5.0vh', paddingTop: '5.0vh'}}>
        <Typography component="h5" variant="h5" align="center" className={classes.fastWinCaption}>
          При реализации проектов мы используем стратегию “БЫСТРЫХ ПОБЕД”
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={(props.width=='xs'||props.width=='sm')?12:4}>
            <Typography component="h6" variant="h6" align="center" style={{color:'white', marginTop:'6.0vh'}}>
              RETAIR
            </Typography>
            <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em', marginBottom: '2.0vh'}}>
              Компания <b>RetAIR</b> использует в работе подход «быстрых побед» -  когда технология реализуется блоками (кейсами), позволяющими получить в короткие сроки эффекты, которые <b>максимизируют чистую прибыль.</b>
            </Typography>
          </Grid>
          <Grid item xs={(props.width=='xs'||props.width=='sm')?12:4} style={{textAlign:'center'}}>
            <img src={rocket} className={classes.rocket} alt={"rocket"}/>
            <Typography component="h6" variant="h6" align="center" style={{color:'white', marginTop:'4.75vh'}}>
              Ускоренная цифровизация
            </Typography>
            <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em', marginBottom: '2.0vh'}}>
              Постоянная апробация, быстрый запуск и модульность
            </Typography>
          </Grid>
          <Grid item xs={(props.width=='xs'||props.width=='sm')?12:4} style={{textAlign:'center'}}>
            <img src={elephant} className={classes.elephant} alt={"elephant"}/>
            <Typography component="h6" variant="h6" align="center" style={{color:'white'}}>
              Классический подход
            </Typography>
            <Typography className={classes.typography} style={{color:'white', fontSize:'1.0em', marginBottom: '2.0vh'}}>
              Несколько крупных и долгосрочных проектов по развитию
            </Typography>
          </Grid>
        </Grid>
        <img src={fastWinStrategyImg} className={classes.fastWinStrategyImg} alt={"fastWinStrategyImg"}/>
      </Container>
    </div>
  )
}

export default FastWinStrategy
