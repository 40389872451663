const localStorageKey = '__retair_token__'

function handleUserResponse(key,token) {
  window.localStorage.setItem(key, token)
}

function logout() {
  window.localStorage.removeItem(localStorageKey)
  return Promise.resolve()
}

function getToken() {
  return window.localStorage.getItem(localStorageKey)
}

export {localStorageKey, handleUserResponse, getToken, logout}
